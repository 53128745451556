export const GREEN_COLOR = '#87d068';
export const YELLOW_COLOR = '#ffc107';
export const RED_COLOR = '#ff4d4f';

export default function getRiskLevelLetterAndColor(riskLevel: number): {
  scoreLetter: string;
  scoreColor: string;
} {
  if (riskLevel <= 1.5) {
    return { scoreLetter: 'A', scoreColor: GREEN_COLOR };
  }
  if (riskLevel <= 3.5) {
    return { scoreLetter: 'B', scoreColor: GREEN_COLOR };
  }
  if (riskLevel <= 5.5) {
    return { scoreLetter: 'C', scoreColor: YELLOW_COLOR };
  }
  if (riskLevel <= 7.5) {
    return { scoreLetter: 'D', scoreColor: YELLOW_COLOR };
  }
  if (riskLevel <= 8.5) {
    return { scoreLetter: 'E', scoreColor: RED_COLOR };
  }
  return { scoreLetter: 'F', scoreColor: RED_COLOR };
}
