import { memo, type ReactNode } from 'react';
import styled, { CSSProperties } from 'styled-components';

import theme from '~/theme';

const WrapperDiv = styled.div<{ $isActive: boolean }>`
  display: inline-flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 10px;

  .give_opacity {
    opacity: ${(props) => (props.$isActive ? 1 : 0.25)};
    transition: opacity 0.1s ease-in-out;
  }

  &:hover {
    .give_opacity {
      opacity: ${(props) => (props.$isActive ? 1 : 0.5)};
    }
  }
`;

const TitleH2 = styled.h2`
  line-height: 1;
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  color: ${theme.colors.darkBlue};
`;

const TitleFlexDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

interface Props {
  icon: ReactNode;
  title: ReactNode;
  afterTitle?: ReactNode;
  isActive: boolean;
  onClick?: () => void;
  style?: CSSProperties;
  'data-id'?: string;
}

const SectionTitle = memo(
  ({ icon, title, afterTitle, isActive, onClick, style, 'data-id': dataId }: Props) => (
    <WrapperDiv
      style={{ ...style, cursor: onClick ? 'pointer' : style?.cursor || 'default' }}
      data-id={dataId}
      onClick={onClick}
      $isActive={isActive}
    >
      <span className="give_opacity">{icon}</span>
      <TitleH2>
        <TitleFlexDiv>
          <span className="give_opacity">{title}</span>
          {afterTitle}
        </TitleFlexDiv>
      </TitleH2>
    </WrapperDiv>
  ),
);

SectionTitle.displayName = 'SectionTitle';

export default SectionTitle;
