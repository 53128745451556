import orderBy from 'lodash/orderBy';

import type { CompanyFeatures } from '~/hooks/useCompanyFeatures';
import i18n from '~/locales/i18n';
import type { EQUIPMENT_STATUS } from '~/types/equipment';
import type { Indicator } from '~/types/indicator';

import getEquipmentStatusDysfunctionsColor from '../equipment/getEquipmentStatusDysfunctionsColor';

export default function computeIndicators({
  activityLevelSensorValue,
  activityLevelSensorStatus,
  isOffline,
  isConnectionLost,
  companyFeatures,
}: {
  activityLevelSensorValue: number | undefined;
  activityLevelSensorStatus: EQUIPMENT_STATUS | undefined;
  isOffline: boolean;
  isConnectionLost: boolean;
  companyFeatures: {
    runningDetectionIndicator: CompanyFeatures['runningDetectionIndicator'];
  };
}): {
  indicators: Indicator[];
  riskLevel: number | null;
} {
  const runningDetection: Omit<Indicator, 'name' | 'steps'> = (() => {
    switch (activityLevelSensorValue || 0) {
      case 3:
        return {
          value: 9,
          textualValue: i18n.t('indicators.runningDetectionSteps.running'),
        };
      case 2:
      case 1:
      case 0:
      default:
        return {
          value: 1,
          textualValue: i18n.t('indicators.runningDetectionSteps.idle'),
        };
    }
  })();

  const indicators: Indicator[] = [
    ...(companyFeatures.runningDetectionIndicator &&
    getEquipmentStatusDysfunctionsColor(activityLevelSensorStatus).isWorking
      ? [
          {
            ...runningDetection,
            name: i18n.t('indicators.runningDetection'),
            steps: 2,
          },
        ]
      : []),
  ];

  const riskLevel: number = Math.max(...indicators.map((indicator) => indicator.value));

  const hasIndicators: boolean = indicators.length > 0 && !isOffline && !isConnectionLost;

  if (!hasIndicators) {
    return {
      indicators: [],
      riskLevel: null,
    };
  }

  return {
    indicators: orderBy(indicators, ['value'], ['desc']),
    riskLevel,
  };
}
