import { memo, useEffect, useRef } from 'react';

import useAgentsContext from '~/context/useAgentsContext';
import useCompanyFeatures from '~/hooks/useCompanyFeatures';
import useSubsidiary from '~/hooks/useSubsidiary';
import i18n from '~/locales/i18n';
import getIndicatorLevelColor from '~/utils/indicators/getIndicatorLevelColor';
import getRiskLevelLetterAndColor, {
  RED_COLOR,
} from '~/utils/indicators/getRiskLevelLetterAndColor';
import notification from '~/utils/notification';
import curateUrl from '~/utils/parse/curateUrl';

const alarm = new Audio(curateUrl('/sounds/risk-level-notification-sound.mp4'));

const PlaySoundWhenRedRiskLevelListener = memo(() => {
  const { agents } = useAgentsContext();
  const { currentSubsidiary } = useSubsidiary();
  const { showIndicatorsFeature } = useCompanyFeatures();

  const notificationKeysRef = useRef<Set<string>>(new Set());

  const prevAgentsRef = useRef<typeof agents>([]);
  const prevSubsidiaryIdRef = useRef<string | undefined>(currentSubsidiary?.id);

  useEffect(() => {
    if (
      currentSubsidiary?.id &&
      prevSubsidiaryIdRef.current &&
      currentSubsidiary?.id !== prevSubsidiaryIdRef.current
    ) {
      notificationKeysRef.current.forEach((key) => {
        notification.destroy(key);
      });
      notificationKeysRef.current.clear();
    }
    prevSubsidiaryIdRef.current = currentSubsidiary?.id;
  }, [currentSubsidiary?.id]);

  useEffect(() => {
    if (!showIndicatorsFeature) {
      return;
    }

    const timestamp = Date.now().toString();

    agents.forEach((agent) => {
      const prevAgent = prevAgentsRef.current.find((a) => a.id === agent.id);

      const { scoreColor: prevScoreColor } = getRiskLevelLetterAndColor(prevAgent?.riskLevel || 0);
      const { scoreColor: newScoreColor } = getRiskLevelLetterAndColor(agent?.riskLevel || 0);

      if (prevAgent && prevScoreColor !== RED_COLOR && newScoreColor === RED_COLOR) {
        alarm.play();
        const key = `high-risk-${agent.id}-${timestamp}`;
        notificationKeysRef.current.add(key);
        notification.warning({
          key,
          message: i18n.t('indicators.highRiskLevelOnAgent', {
            agentName: agent.completeName,
          }),
          description: (
            <>
              <div>
                {i18n.t('indicators.highRiskLevelOnAgentDescription', {
                  agentName: agent.completeName,
                })}
              </div>
              {agent.indicators.map((indicator) => (
                <div key={JSON.stringify(indicator)}>
                  {indicator.name}:{' '}
                  <span style={{ color: getIndicatorLevelColor(indicator.value) }}>
                    {indicator.textualValue || indicator.value}
                  </span>
                </div>
              ))}
            </>
          ),
          duration: null,
          onClose: () => {
            notificationKeysRef.current.delete(key);
          },
        });
      }
    });

    prevAgentsRef.current = agents;
  }, [agents, showIndicatorsFeature]);

  return null;
});

PlaySoundWhenRedRiskLevelListener.displayName = 'PlaySoundWhenRedRiskLevelListener';

export default PlaySoundWhenRedRiskLevelListener;
