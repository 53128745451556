import { Progress } from 'antd';
import { memo, useMemo } from 'react';
import styled from 'styled-components';

import i18n from '~/locales/i18n';
import theme from '~/theme';
import type { Agent } from '~/types/agent';
import getRiskLevelLetterAndColor from '~/utils/indicators/getRiskLevelLetterAndColor';

import MetricCard, { CardDiv, H3 } from './components/MetricCard';

const WrapperDiv = styled.div`
  margin-bottom: 1.5rem;
`;

const RiskScoreDiv = styled.div`
  margin-bottom: 8px;
`;

const GridDiv = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-gap: 8px;
`;

const FlexDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const RiskScoreLetterDiv = styled.div`
  font-size: 1.5rem;
  font-weight: 600;
  color: ${theme.colors.white};
  padding: 0.5rem 1rem;
  border-radius: 8px;
`;

interface Props {
  agent: Agent | undefined;
}

const SectionIndicators = memo(({ agent }: Props) => {
  const riskLevel = agent?.riskLevel || 0;
  const indicators = agent?.indicators || [];

  const { scoreLetter, scoreColor } = useMemo(
    () => getRiskLevelLetterAndColor(riskLevel),
    [riskLevel],
  );

  return (
    <WrapperDiv>
      {riskLevel > 0 && (
        <RiskScoreDiv>
          <CardDiv>
            <FlexDiv>
              <RiskScoreLetterDiv style={{ backgroundColor: scoreColor }}>
                {scoreLetter}
              </RiskScoreLetterDiv>
              <div>
                <H3>{i18n.t('indicators.riskLevel')}</H3>
                <Progress
                  percent={riskLevel * 10}
                  steps={10}
                  strokeColor={scoreColor}
                  style={{
                    width: '100%',
                  }}
                  showInfo={false}
                />
              </div>
            </FlexDiv>
          </CardDiv>
        </RiskScoreDiv>
      )}
      <GridDiv>
        {indicators.map((indicator) => (
          <MetricCard key={JSON.stringify(indicator)} indicator={indicator} />
        ))}
      </GridDiv>
    </WrapperDiv>
  );
});

SectionIndicators.displayName = 'SectionIndicators';

export default SectionIndicators;
