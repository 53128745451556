import ExclamationCircleTwoTone from '@ant-design/icons/ExclamationCircleTwoTone';
import { Tooltip } from 'antd';
import { memo, useEffect, useState } from 'react';
import { IoStatsChartSharp } from 'react-icons/io5';
import { MdSettingsInputComponent } from 'react-icons/md';
import styled from 'styled-components';

import useCompanyFeatures from '~/hooks/useCompanyFeatures';
import i18n from '~/locales/i18n';
import theme from '~/theme';
import type { Agent } from '~/types/agent';
import getDysfunctionalEquipment from '~/utils/equipment/getDysfunctionalEquipment';
import {
  GREY_TWO_TONE_COLOR,
  YELLOW_TWO_TONE_COLOR,
} from '~/utils/equipment/getEquipmentStatusDysfunctionsColor';

import SectionEquipment from './tabs/SectionEquipment';
import SectionIndicators from './tabs/SectionIndicators';
import SectionTitle from '../SectionTitle';

const BigFlexDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

interface Props {
  agent: Agent | undefined;
}

const SectionDataTabs = memo(({ agent }: Props) => {
  const { companyFeatures } = useCompanyFeatures();

  const hasIndicators = agent && agent.indicators.length > 0;

  const [activeTab, setActiveTab] = useState<'indicators' | 'equipment'>(
    hasIndicators ? 'indicators' : 'equipment',
  );

  useEffect(() => {
    setActiveTab(hasIndicators ? 'indicators' : 'equipment');
  }, [hasIndicators]);

  const dysfunctionalEquipment = agent
    ? getDysfunctionalEquipment({
        agent,
        companyFeatures,
      })
    : [];

  return (
    <div>
      <BigFlexDiv>
        {hasIndicators && (
          <SectionTitle
            data-id="SectionTitle-SectionIndicators"
            icon={<IoStatsChartSharp color={theme.colors.darkBlue} size={23} />}
            title={i18n.t('carrierDetailsPopup.informationTypes.indicators')}
            onClick={() => setActiveTab('indicators')}
            isActive={activeTab === 'indicators'}
          />
        )}
        <SectionTitle
          data-id="SectionTitle-SectionEquipment"
          icon={<MdSettingsInputComponent color={theme.colors.darkBlue} size={23} />}
          title={i18n.t('carrierDetailsPopup.informationTypes.equipment')}
          afterTitle={
            dysfunctionalEquipment.length > 0 ? (
              <Tooltip
                title={i18n.t('carrierDetailsPopup.equipment.foundDysfunction')}
                placement="right"
              >
                <ExclamationCircleTwoTone
                  twoToneColor={
                    agent?.isConnectionLost || agent?.isOffline
                      ? GREY_TWO_TONE_COLOR
                      : YELLOW_TWO_TONE_COLOR
                  }
                  style={{ fontSize: '16px', cursor: 'help' }}
                />
              </Tooltip>
            ) : undefined
          }
          onClick={hasIndicators ? () => setActiveTab('equipment') : undefined}
          isActive={activeTab === 'equipment'}
        />
      </BigFlexDiv>
      {activeTab === 'indicators' && <SectionIndicators agent={agent} />}
      {activeTab === 'equipment' && <SectionEquipment agent={agent} />}
    </div>
  );
});

SectionDataTabs.displayName = 'SectionDataTabs';

export default SectionDataTabs;
