import { memo, useMemo } from 'react';

import SectionList, {
  type SectionListItem,
} from '~/components/DetailsPopup/components/SectionList';
import { ACTIVITY_LEVEL_UNIT } from '~/config/units';
import useModalsContext from '~/context/useModalsContext';
import useCompanyFeatures from '~/hooks/useCompanyFeatures';
import i18n from '~/locales/i18n';
import type { Agent } from '~/types/agent';
import getEquipmentStatusDysfunctionsColor from '~/utils/equipment/getEquipmentStatusDysfunctionsColor';
import formatNumberWithUnit from '~/utils/parse/formatNumberWithUnit';

interface Props {
  agent: Agent | undefined;
}

const ContentActivityLevel = memo(({ agent }: Props) => {
  const { companyFeatures } = useCompanyFeatures();
  const { openModal } = useModalsContext();

  const { isWorking } = getEquipmentStatusDysfunctionsColor(
    agent?.equipmentStatus?.activityLevel?.status,
  );

  const activityLevelValue = formatNumberWithUnit({
    number: isWorking ? agent?.sensors?.activityLevel?.value : undefined,
    unit: ACTIVITY_LEVEL_UNIT,
  });

  const isNotAvailable = activityLevelValue === 'n/a';

  const list: SectionListItem[] = useMemo(
    () => [
      ...(companyFeatures.activityLevelSensor
        ? [
            {
              style: {
                opacity: isNotAvailable ? 0.4 : 1,
              },
              labelText: i18n.t('carrierDetailsPopup.physiologicalSensors.activityLevel'),
              valueText: isNotAvailable
                ? activityLevelValue
                : i18n.t(`indicators.activityLevelSteps.${activityLevelValue}`),
              'data-id': 'activityLevelSensor',
              onClickLabel: () => {
                openModal({
                  type: 'measurementGraph',
                  measurementKind: 'activityLevel',
                  title: i18n.t('carrierDetailsPopup.physiologicalSensors.activityLevel'),
                  agentName: agent?.completeName || '',
                  brainId: agent?.deviceName || '',
                  carrierId: agent?.id || '',
                });
              },
            },
          ]
        : []),
    ],
    [
      companyFeatures.activityLevelSensor,
      activityLevelValue,
      isNotAvailable,
      agent?.completeName,
      agent?.deviceName,
      agent?.id,
      openModal,
    ],
  );

  if (!agent) {
    return null;
  }

  return <SectionList data-id="SectionPhysiologicalSensors" elements={list} />;
});

ContentActivityLevel.displayName = 'ContentActivityLevel';

export default ContentActivityLevel;
