import { gql, useQuery, type ApolloError } from '@apollo/client';
import { useMemo } from 'react';

import { MEASUREMENT_ITEMS_QUERY_BODY } from '~/apollo/operations/shared';
import {
  GAS_SENSOR_TYPE,
  SENSOR_NAME_QUERY,
  SENSOR_NAME_VARIABLE,
  type GasSensorType,
} from '~/types/sensor';

import type { MeasurementDataPoint } from './types';

interface GasMeasurementHistoryQueryVariables {
  carrierId: string;
}

interface GasMeasurementHistoryQueryData {
  carrier: {
    device: {
      __typename?: 'Device';
      gas: {
        __typename?: 'MeasurementConnection';
        items: GasSensorType[];
      };
    };
  };
}

export default function useQueryGasMeasurementHistory({
  carrierId,
  skip,
}: {
  carrierId: string;
  skip: boolean;
}): {
  ch4HcDataPoints: MeasurementDataPoint[];
  coDataPoints: MeasurementDataPoint[];
  co2DataPoints: MeasurementDataPoint[];
  h2sDataPoints: MeasurementDataPoint[];
  o2DataPoints: MeasurementDataPoint[];
  isLoading: boolean;
  error?: ApolloError;
  refetch: () => Promise<void>;
} {
  const { loading, error, data, refetch } = useQuery<
    GasMeasurementHistoryQueryData,
    GasMeasurementHistoryQueryVariables
  >(
    gql`
    query QueryGasMeasurementHistory($carrierId: ID!) {
        carrier(id: $carrierId) {
            device {
              ${SENSOR_NAME_VARIABLE.gas}: measurements(type: "${SENSOR_NAME_QUERY.gas}", limit: 1000) ${MEASUREMENT_ITEMS_QUERY_BODY}
            }
          }
      }
    
  `,
    {
      variables: { carrierId },
      fetchPolicy: 'no-cache',
      skip,
    },
  );

  const parsedItems = useMemo(
    () =>
      (data?.carrier?.device?.gas?.items || []).map((items) => ({
        ...items,
        value: JSON.parse(items.value as unknown as string) as GasSensorType['value'],
      })),
    [data?.carrier?.device?.gas?.items],
  );

  return useMemo(
    () => ({
      ch4HcDataPoints: parsedItems
        .map((item) => ({
          value: parseFloat(`${item.value[GAS_SENSOR_TYPE.ch4_hc]}`),
          timestamp: item.timestamp,
        }))
        .filter((dataPoint) => Number.isFinite(dataPoint.value)),
      coDataPoints: parsedItems
        .map((item) => ({
          value: parseFloat(`${item.value[GAS_SENSOR_TYPE.co]}`),
          timestamp: item.timestamp,
        }))
        .filter((dataPoint) => Number.isFinite(dataPoint.value)),
      co2DataPoints: parsedItems
        .map((item) => ({
          value: parseFloat(`${item.value[GAS_SENSOR_TYPE.co2]}`),
          timestamp: item.timestamp,
        }))
        .filter((dataPoint) => Number.isFinite(dataPoint.value)),
      h2sDataPoints: parsedItems
        .map((item) => ({
          value: parseFloat(`${item.value[GAS_SENSOR_TYPE.h2s]}`),
          timestamp: item.timestamp,
        }))
        .filter((dataPoint) => Number.isFinite(dataPoint.value)),
      o2DataPoints: parsedItems
        .map((item) => ({
          value: parseFloat(`${item.value[GAS_SENSOR_TYPE.o2]}`),
          timestamp: item.timestamp,
        }))
        .filter((dataPoint) => Number.isFinite(dataPoint.value)),
      isLoading: loading,
      error,
      refetch: async () => {
        await refetch();
      },
    }),
    [parsedItems, loading, error, refetch],
  );
}
