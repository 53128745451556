import { Empty, Spin } from 'antd';
import { memo, useMemo } from 'react';
import styled from 'styled-components';

import useAlarmsContext from '~/context/useAlarmsContext';
import useCompany from '~/hooks/useCompany';
import useSubsidiary from '~/hooks/useSubsidiary';
import i18n from '~/locales/i18n';
import theme from '~/theme';
import sortAgentsByStatusRiskLevelAndName from '~/utils/agent/sortAgentsByStatusRiskLevelAndName';
import curateUrl from '~/utils/parse/curateUrl';

import AgentFullCard from './components/AgentFullCard';
import BatteryAndLteSingalIcons from './components/BatteryAndLteSignalIcons';
import BrainBatteryLteSignalPopover from './components/BrainBatteryLteSignalPopover';
import DetailsPopupFooter from './components/DetailsPopupFooter';
import DetailsPopupHeader from './components/DetailsPopupHeader';
import PaperCard from './components/PaperCard';
import SectionInfo from './components/SectionInfo';
import SectionTitle from './components/SectionTitle';
import StatusBadge from './components/StatusBadge';
import VehicleAgentTabs from './components/VehicleAgentTabs';
import { DETAILS_POPUP_TYPE } from './config/types';
import useDetailsPopupContext from './context/useDetailsPopupContext';

const WrapperDiv = styled.div<{ $hasAlertBottomBanner: boolean }>`
  width: 100%;
  max-width: 100%;
  height: calc(
    100dvh - ${theme.dimensions.navbarHeight}px -
      ${(props) =>
        props.$hasAlertBottomBanner ? `${theme.dimensions.alarmsBottomBannerHeight}px` : '0px'}
  );
  z-index: ${theme.layers.base};
  overflow: auto;
  background: ${theme.colors.white};
  user-select: none;
  border-right: 1px solid ${theme.colors.lightGrey};
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
`;

const EmptyCenterDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 18px;
  width: 100%;
  height: 100%;
`;

const ContentFlexDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const ContentDiv = styled.div`
  padding: 12px 16px 0;
`;

const BrainHorizontalDiv = styled.div`
  margin: 0 0 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${theme.medias.extraSmall} {
    flex-direction: column;
    gap: 12px;
    align-items: flex-start;
  }
`;

const BatteryAndStatusDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

interface Props {
  isLoading: boolean;
}

const DetailsPopupContent = memo(({ isLoading }: Props) => {
  const { currentCompany } = useCompany();
  const { currentSubsidiary } = useSubsidiary();
  const { type, agent, vehicle } = useDetailsPopupContext();
  const { hasAlertBottomBanner } = useAlarmsContext();

  const brainStatus = useMemo(() => {
    if (agent?.isOffline) {
      return <span style={{ opacity: 0.5 }}>{i18n.t('brainStatus.offline')}</span>;
    }
    if (agent?.isConnectionLost) {
      return <span style={{ opacity: 0.5 }}>{i18n.t('brainStatus.connectionLost')}</span>;
    }
    if (
      typeof agent?.sensors?.battery?.value?.battery_level === 'number' &&
      agent?.sensors?.battery?.value?.battery_level < 20
    ) {
      return <span style={{ color: theme.colors.red }}>{i18n.t('brainStatus.lowBattery')}</span>;
    }
    return i18n.t('brainStatus.online');
  }, [agent?.isConnectionLost, agent?.isOffline, agent?.sensors?.battery?.value?.battery_level]);

  const isTypeAgent = type === DETAILS_POPUP_TYPE.agent;
  const isTypeVehicle = type === DETAILS_POPUP_TYPE.vehicle;

  const allAgents = (isTypeAgent && agent ? [agent] : vehicle?.agents || []).filter((a) => !!a);
  const sortedAgents = sortAgentsByStatusRiskLevelAndName(allAgents);

  const activeAgent = isTypeAgent ? sortedAgents[0] : agent;

  const vehicleAgents =
    agent?.isOffline && activeAgent ? [activeAgent, ...sortedAgents] : sortedAgents;

  const hasValidData = !!agent || !!vehicle;

  const emptyDescription = useMemo(() => {
    if (!currentCompany?.id) {
      return i18n.t('carrierDetailsPopup.noCompanySelected');
    }
    if (!currentSubsidiary?.id) {
      return i18n.t('carrierDetailsPopup.noSubsidiarySelected');
    }
    return i18n.t('carrierDetailsPopup.agentNotFound');
  }, [currentCompany?.id, currentSubsidiary?.id]);

  return (
    <WrapperDiv
      $hasAlertBottomBanner={hasAlertBottomBanner}
      data-id={isTypeAgent ? 'agent-details-popup' : 'vehicle-details-popup'}
    >
      {isLoading ? (
        <EmptyCenterDiv>
          <Spin size="large" />
        </EmptyCenterDiv>
      ) : (
        <div style={{ height: '100%' }}>
          {!hasValidData ? (
            <EmptyCenterDiv>
              <Empty description={emptyDescription} />
              <Spin />
            </EmptyCenterDiv>
          ) : (
            <ContentFlexDiv>
              <div>
                <DetailsPopupHeader type={type} agent={agent} vehicle={vehicle} />
                <ContentDiv>
                  {isTypeVehicle && (
                    <VehicleAgentTabs agents={vehicleAgents} activeAgentId={activeAgent?.id} />
                  )}
                  <BrainHorizontalDiv>
                    <BrainBatteryLteSignalPopover
                      agent={agent}
                      showBrain
                      showLteSignal={false}
                      showBattery={false}
                      placement="bottomLeft"
                    >
                      <SectionTitle
                        style={{ marginBottom: 0, cursor: 'help' }}
                        data-id="SectionTitle-Brain"
                        icon={
                          <img
                            src={curateUrl('/icons/brain.svg')}
                            alt="Brain"
                            style={{
                              color: theme.colors.darkBlue,
                              height: 24,
                            }}
                          />
                        }
                        title={
                          <>
                            {i18n.t('common.wearinBrain')}: {brainStatus}
                          </>
                        }
                        isActive
                      />
                    </BrainBatteryLteSignalPopover>
                    <BatteryAndStatusDiv>
                      <BrainBatteryLteSignalPopover
                        agent={agent}
                        showBrain={false}
                        showLteSignal
                        showBattery
                        placement="bottom"
                      >
                        <BatteryAndLteSingalIcons agent={agent} />
                      </BrainBatteryLteSignalPopover>
                      <StatusBadge agent={agent} />
                    </BatteryAndStatusDiv>
                  </BrainHorizontalDiv>
                  <PaperCard>
                    <SectionInfo agent={agent} vehicle={vehicle} />
                  </PaperCard>
                  <AgentFullCard key={activeAgent?.id} agent={activeAgent} />
                </ContentDiv>
              </div>
              <DetailsPopupFooter type={type} agent={agent} />
            </ContentFlexDiv>
          )}
        </div>
      )}
    </WrapperDiv>
  );
});

DetailsPopupContent.displayName = 'DetailsPopupContent';

export default DetailsPopupContent;
