import CopyOutlined from '@ant-design/icons/CopyOutlined';
import { Tooltip } from 'antd';
import { memo, useState, type ReactNode } from 'react';
import styled, { css } from 'styled-components';

import i18n from '~/locales/i18n';
import theme from '~/theme';

const commonStyles = css`
  color: ${theme.colors.darkBlue};
  font-size: 14px;
`;

const LabelSpan = styled.span`
  ${commonStyles}
  font-weight: bold;
`;

const ValueSpan = styled.span`
  ${commonStyles}
`;

const TransparentButton = styled.button`
  ${commonStyles}
  margin: 0;
  padding: 0;
  border: 0;
  outline: none;
  background: transparent;
  cursor: pointer;
  text-align: left;

  &:hover {
    opacity: 0.85;
  }
`;

interface Props {
  label: ReactNode | undefined;
  value: string | undefined;
  hasCopyToClipboard: boolean;
  hasOpacity?: boolean;
  'data-id': string;
}

const LabelWithValue = memo(
  ({ label, value, hasCopyToClipboard, hasOpacity, 'data-id': dataId }: Props) => {
    const copyToClipboardText = i18n.t<string>('general.clipboard.copyToClipboard');

    const [tooltip, setTooltip] = useState<string>(copyToClipboardText);

    return (
      <div data-id={dataId}>
        {label ? <LabelSpan>{`${label}: `}</LabelSpan> : null}
        {value &&
          (hasCopyToClipboard && !hasOpacity ? (
            <Tooltip title={tooltip} placement="right">
              <TransparentButton
                style={{ opacity: hasOpacity ? 0.5 : 1 }}
                onClick={() => {
                  navigator.clipboard.writeText(value || '');
                  setTooltip(i18n.t<string>('general.clipboard.copied'));
                }}
                onPointerLeave={() => {
                  setTimeout(() => {
                    setTooltip(copyToClipboardText);
                  }, 100);
                }}
              >
                {value}
                <CopyOutlined style={{ marginLeft: '6px' }} />
              </TransparentButton>
            </Tooltip>
          ) : (
            <ValueSpan style={{ opacity: hasOpacity ? 0.5 : 1 }}>{value}</ValueSpan>
          ))}
      </div>
    );
  },
);

LabelWithValue.displayName = 'LabelWithValue';

export default LabelWithValue;
