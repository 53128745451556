import EyeOutlined from '@ant-design/icons/EyeOutlined';
import { Button, Popover } from 'antd';
import { memo, useMemo } from 'react';
import Highlighter from 'react-highlight-words';
import styled from 'styled-components';

import Link from '~/components/Link';
import routes from '~/config/routes';
import useCompanyFeatures from '~/hooks/useCompanyFeatures';
import i18n from '~/locales/i18n';
import theme from '~/theme';
import type { Agent } from '~/types/agent';
import getAgentStatusColor from '~/utils/agent/getAgentStatusColor';
import getAlarmColorFromLevel from '~/utils/alarm/getAlarmColorFromLevel';
import getIndicatorLevelColor from '~/utils/indicators/getIndicatorLevelColor';
import getRiskLevelLetterAndColor from '~/utils/indicators/getRiskLevelLetterAndColor';

const AGENT_HEADER_SPACING = '0.25rem';

const CIRCLE_WIDTH = '38px';

const PopoverSummaryDl = styled.dl`
  margin: 0;

  & > div {
    display: flex;
    gap: 4px;

    dt {
      margin: 0;
      font-weight: bold;
    }

    dd {
      margin: 0;
    }
  }
`;

const WrapperDiv = styled.div<{ $isSelected: boolean }>`
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, auto);
  align-items: center;
  grid-gap: 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  background: ${(props) => (props.$isSelected ? theme.colors.lightGrey : theme.colors.white)};
  padding-right: 8px;

  &:hover {
    background: ${theme.colors.lightGrey};
  }
`;

const StyledLink = styled(Link)`
  display: grid;
  grid-template-columns: minmax(0, ${CIRCLE_WIDTH}) minmax(0, 1fr);
  align-items: center;
  padding: 8px;
  grid-gap: 12px;
`;

const ProfileCircleDiv = styled.div`
  align-items: center;
  display: flex;
  font-weight: regular;
  justify-content: center;
  border-radius: 32px;
  margin-right: 0;
  font-size: 12px;
  height: ${CIRCLE_WIDTH};
  width: ${CIRCLE_WIDTH};
  margin: 0;
`;

const RightDiv = styled.div``;

const TitleH1 = styled.h1`
  margin: 0;
  font-size: 16px;
  color: ${theme.colors.black};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SubtitleH2 = styled.h2`
  margin: ${AGENT_HEADER_SPACING} 0 0;
  font-size: 12px;
  opacity: 0.7;
  color: rgba(0, 0, 0, 0.75);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const RiskLevelDiv = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: ${theme.colors.white};
  border-radius: 4px;
  margin-right: 4px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonsDiv = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`;

const RiskLevelStyledLink = styled(Link)`
  height: 100%;
  display: flex;
  align-items: center;
`;

interface Props {
  agent: Agent;
  searchText: string;
  idFromQuery: string | undefined;
  vehicleFromQuery: string | undefined;
}

const AgentButton = memo(({ agent, searchText, idFromQuery, vehicleFromQuery }: Props) => {
  const agentStatusColor = useMemo(() => getAgentStatusColor(agent), [agent]);

  const { showRealtimeLocationFeature } = useCompanyFeatures();

  const showRealtimeLocation = showRealtimeLocationFeature(agent);

  const hasIndicators = agent && agent.indicators.length > 0;

  const { scoreLetter, scoreColor } = useMemo(
    () => getRiskLevelLetterAndColor(agent.riskLevel || 0),
    [agent.riskLevel],
  );

  const currentUrl = routes.map({
    id: idFromQuery,
    vehicle: vehicleFromQuery,
  });

  const navigationUrl = routes.map({
    id: agent?.id,
    vehicle: agent?.attributes?.plate_number,
  });

  const isSelected = currentUrl === navigationUrl;

  const subtitleTexts: string[] = useMemo(() => {
    const subtitles: string[] = [];
    if (agent?.attributes?.plate_number) {
      subtitles.push(agent.attributes.plate_number);
    }
    if (agent?.team) {
      subtitles.push(agent.team);
    }
    return subtitles;
  }, [agent?.attributes?.plate_number, agent?.team]);

  return (
    <Popover
      placement="right"
      content={
        hasIndicators ? (
          <PopoverSummaryDl>
            {agent.alertLevel && (
              <div>
                <dt>{i18n.t('common.alertLevel')}:</dt>
                <dd style={{ color: getAlarmColorFromLevel(agent.alertLevel).text }}>
                  {i18n.t(`general.alarm.level.${agent.alertLevel.toLowerCase()}`)}
                </dd>
              </div>
            )}
            <div>
              <dt>{i18n.t('indicators.riskLevel')}:</dt>
              <dd style={{ color: scoreColor }}>
                {scoreLetter} ({agent.riskLevel || 0}/10)
              </dd>
            </div>
            {agent.indicators.map((indicator) => (
              <div key={JSON.stringify(indicator)}>
                <dt>{indicator.name}:</dt>
                <dd style={{ color: getIndicatorLevelColor(indicator.value) }}>
                  {indicator.textualValue || indicator.value}
                </dd>
              </div>
            ))}
          </PopoverSummaryDl>
        ) : null
      }
    >
      <WrapperDiv $isSelected={isSelected}>
        <StyledLink to={isSelected || !showRealtimeLocation ? routes.map() : navigationUrl}>
          <ProfileCircleDiv
            style={{
              backgroundColor: agentStatusColor,
              borderColor: agentStatusColor,
              color: theme.colors.white,
            }}
            data-id="details-popup-bullet-label"
          >
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[searchText?.trim()]}
              autoEscape
              textToHighlight={agent?.attributes?.acronym || ''}
            />
          </ProfileCircleDiv>
          <RightDiv>
            <TitleH1 data-id="details-popup-title">
              <Highlighter
                highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                searchWords={[searchText?.trim()]}
                autoEscape
                textToHighlight={agent?.completeName || ''}
              />
            </TitleH1>
            {subtitleTexts.length > 0 && (
              <SubtitleH2 data-id="details-popup-subtitle">
                {subtitleTexts.map((text, index) => {
                  const key = `${text}-${index}`;
                  return (
                    <span key={key}>
                      <Highlighter
                        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                        searchWords={[searchText?.trim()]}
                        autoEscape
                        textToHighlight={text || ''}
                      />
                      {index < subtitleTexts.length - 1 && ' | '}
                    </span>
                  );
                })}
              </SubtitleH2>
            )}
          </RightDiv>
        </StyledLink>
        <ButtonsDiv>
          {hasIndicators && (
            <RiskLevelStyledLink to={routes.map({ id: agent?.id })}>
              <RiskLevelDiv style={{ backgroundColor: scoreColor }}>{scoreLetter}</RiskLevelDiv>
            </RiskLevelStyledLink>
          )}
          <Link to={routes.status({ id: agent?.id })}>
            <Button style={{ width: '32px', height: '32px' }}>
              <EyeOutlined />
            </Button>
          </Link>
        </ButtonsDiv>
      </WrapperDiv>
    </Popover>
  );
});

AgentButton.displayName = 'AgentButton';

export default AgentButton;
