import PauseCircleOutlined from '@ant-design/icons/PauseCircleOutlined';
import PlayCircleOutlined from '@ant-design/icons/PlayCircleOutlined';
import { Button, Tooltip } from 'antd';
import { memo, useEffect, useMemo } from 'react';
import styled from 'styled-components';

import useMutationRequestVideoStreamStatus from '~/apollo/hooks/videoStream/useMutationRequestVideoStreamStatus';
import useQueryWithSubscriptionCarrierVideoStream from '~/apollo/hooks/videoStream/useQueryWithSubscriptionCarrierVideoStream';
import useAlarmsContext from '~/context/useAlarmsContext';
import useCurrentUserContext from '~/context/useCurrentUserContext';
import useCompanyFeatures from '~/hooks/useCompanyFeatures';
import i18n from '~/locales/i18n';
import type { Agent } from '~/types/agent';
import { STREAM_REQUESTED_STATUS, STREAM_STATE, STREAM_TYPE } from '~/types/videoStream';
import logger from '~/utils/logger';
import notification from '~/utils/notification';
import getYesOrNo from '~/utils/parse/getYesOrNo';

import VideoStreamPlayerElement from './VideoStreamPlayerElement';

const BottomDiv = styled.div`
  margin-top: 8px;
  display: grid;
  grid-template-columns: minmax(0, auto) minmax(0, 1fr);
  align-items: center;
  grid-gap: 8px;
`;

const ActionsDiv = styled.div`
  display: inline-grid;
  grid-template-columns: repeat(2, minmax(0, auto));
  grid-gap: 8px;
  align-items: center;
`;

interface Props {
  agent: Agent | undefined;
}

const VideoStreamPlayer = memo(({ agent }: Props) => {
  const { isSuperAdmin } = useCurrentUserContext();
  const { companyFeatures } = useCompanyFeatures();
  const { ongoingAlarms } = useAlarmsContext();

  const {
    videoStream,
    error: videoStreamError,
    isLoading: isVideoStreamLoading,
  } = useQueryWithSubscriptionCarrierVideoStream({
    agent,
  });

  const {
    requestVideoStreamStatus,
    error: requestError,
    requestStatus: requestStatusFromMutation,
    isLoading: isRequestLoading,
  } = useMutationRequestVideoStreamStatus();

  const isLoading = isVideoStreamLoading || isRequestLoading;

  useEffect(() => {
    if (videoStreamError?.message) {
      notification.error({
        message: videoStreamError?.message,
      });
    }
  }, [videoStreamError]);

  useEffect(() => {
    if (requestError?.message) {
      notification.error({
        message: requestError?.message,
      });
    }
  }, [requestError]);

  const requestStatus = requestStatusFromMutation || agent?.requestedVideoStreamStatus;

  const isVideoStreamPlaying =
    agent?.isPlayingVideoStreamV2 || videoStream?.ivs_stream_state === STREAM_STATE.START;

  const shouldVideoSteamBePlaying =
    isVideoStreamPlaying || requestStatus === STREAM_REQUESTED_STATUS.STARTED;

  /**
   * 3 States:
   * - Started by the Dashboard (can stop)
   * - Started by an alarm (can't stop)
   * - Started by an agent from the mobile app (can't stop)
   */

  const shouldPlayVideoFromAlarm = useMemo(
    () =>
      ongoingAlarms
        .filter((alarm) => alarm.activate_video)
        .some((alarm) => alarm.carrier.id === agent?.id),
    [ongoingAlarms, agent?.id],
  );

  const wasStartedByAgent =
    shouldVideoSteamBePlaying &&
    !shouldPlayVideoFromAlarm &&
    requestStatus !== STREAM_REQUESTED_STATUS.STARTED;

  const isStartDisabled = shouldPlayVideoFromAlarm || shouldVideoSteamBePlaying;
  const isStopDisabled = shouldPlayVideoFromAlarm || !shouldVideoSteamBePlaying;

  const isLoadingOrRunning = shouldPlayVideoFromAlarm || shouldVideoSteamBePlaying;

  const isStartOrRequestStartLoading =
    isLoading || requestStatus === STREAM_REQUESTED_STATUS.STARTED;

  const startTooltip = useMemo(() => {
    if (shouldPlayVideoFromAlarm) {
      return i18n.t('carrierDetailsPopup.videoStreaming.startTooltipDisabledHasAlert');
    }
    if (isLoading) {
      return i18n.t('common.loading');
    }
    if (isStartDisabled || shouldVideoSteamBePlaying) {
      return i18n.t('carrierDetailsPopup.videoStreaming.startTooltipDisabledHasStream');
    }
    return i18n.t('carrierDetailsPopup.videoStreaming.startTooltip');
  }, [isLoading, isStartDisabled, shouldPlayVideoFromAlarm, shouldVideoSteamBePlaying]);

  const stopTooltip = useMemo(() => {
    if (shouldPlayVideoFromAlarm) {
      return i18n.t('carrierDetailsPopup.videoStreaming.stopTooltipDisabledHasAlert');
    }
    if (isLoading) {
      return i18n.t('common.loading');
    }
    if (wasStartedByAgent) {
      return i18n.t('carrierDetailsPopup.videoStreaming.stopTooltipDisabledStartedByAgent');
    }
    if (!shouldVideoSteamBePlaying) {
      return i18n.t('carrierDetailsPopup.videoStreaming.stopTooltipDisabledNoStream');
    }
    return i18n.t('carrierDetailsPopup.videoStreaming.stopTooltip');
  }, [isLoading, shouldPlayVideoFromAlarm, shouldVideoSteamBePlaying, wasStartedByAgent]);

  const streamType = useMemo(() => {
    if (agent?.isPlayingVideoStreamV2) {
      return 'KVS';
    }
    switch (videoStream?.ivs_stream_type) {
      case STREAM_TYPE.IVS_REALTIME:
        return 'IVS Realtime';
      case STREAM_TYPE.IVS_LOW_LATENCY:
        return 'IVS Low Latency';
      default:
        return videoStream?.ivs_stream_type || null;
    }
  }, [agent?.isPlayingVideoStreamV2, videoStream?.ivs_stream_type]);

  if (!agent) {
    return null;
  }

  return (
    <>
      <VideoStreamPlayerElement
        key={`${isStartDisabled}${isStopDisabled}${requestStatus}${JSON.stringify(videoStream)}`}
        agent={agent}
        videoStream={videoStream}
        isLoadingOrRunning={isLoadingOrRunning}
        isStartOrRequestStartLoading={isStartOrRequestStartLoading}
        shouldPlayVideoFromAlarm={shouldPlayVideoFromAlarm}
      />
      {companyFeatures.remoteVideoStreamingControl && (
        <BottomDiv>
          <ActionsDiv>
            <Tooltip title={startTooltip} placement="bottomLeft">
              <Button
                icon={<PlayCircleOutlined data-id="video-play-btn" />}
                loading={isLoading}
                disabled={isStartDisabled}
                onClick={async () => {
                  try {
                    if (agent?.id) {
                      await requestVideoStreamStatus({
                        variables: {
                          carrierId: agent.id,
                          requestedStatus: STREAM_REQUESTED_STATUS.STARTED,
                        },
                      });
                    }
                  } catch (error) {
                    logger.error('VideoStreamPlayerLowLatency: Failed to start video stream', {
                      error,
                      videoStream,
                    });
                  }
                }}
              >
                {i18n.t('carrierDetailsPopup.videoStreaming.start')}
              </Button>
            </Tooltip>
            <Tooltip title={stopTooltip} placement="bottomLeft">
              <Button
                icon={<PauseCircleOutlined data-id="video-pause-btn" />}
                loading={isLoading}
                disabled={isStopDisabled}
                onClick={async () => {
                  try {
                    if (agent?.id) {
                      await requestVideoStreamStatus({
                        variables: {
                          carrierId: agent.id,
                          requestedStatus: STREAM_REQUESTED_STATUS.STOPPED,
                        },
                      });
                    }
                  } catch (error) {
                    logger.error('VideoStreamPlayerLowLatency: Failed to stop video stream', {
                      error,
                      videoStream,
                    });
                  }
                }}
              >
                {i18n.t('carrierDetailsPopup.videoStreaming.stop')}
              </Button>
            </Tooltip>
          </ActionsDiv>
          <div
            style={{
              fontSize: '11px',
              opacity: 0.75,
              textOverflow: 'ellipsis',
              overflowX: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >
            {i18n.t('carrierDetailsPopup.videoStreaming.streaming')}:{' '}
            {isVideoStreamLoading ? i18n.t('common.loading') : getYesOrNo(isVideoStreamPlaying)}
            {isSuperAdmin && streamType && ` (${streamType})`}
            <br />
            {i18n.t('carrierDetailsPopup.videoStreaming.requested')}:{' '}
            {isRequestLoading
              ? i18n.t('common.loading')
              : getYesOrNo(requestStatus === STREAM_REQUESTED_STATUS.STARTED)}
          </div>
        </BottomDiv>
      )}
    </>
  );
});

VideoStreamPlayer.displayName = 'VideoStreamPlayer';

export default VideoStreamPlayer;
