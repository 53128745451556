import { memo, useMemo } from 'react';

import SectionList from '~/components/DetailsPopup/components/SectionList';
import {
  AIR_SUPPLY_REMAINING_TIME_UNIT,
  AIR_SUPPLY_TANK_PRESSURE_UNIT,
  AIR_SUPPLY_TEMPERATURE_UNIT,
} from '~/config/units';
import useModalsContext from '~/context/useModalsContext';
import i18n from '~/locales/i18n';
import type { Agent } from '~/types/agent';
import getEquipmentStatusDysfunctionsColor from '~/utils/equipment/getEquipmentStatusDysfunctionsColor';
import formatNumberWithUnit from '~/utils/parse/formatNumberWithUnit';

const DECIMALS = 0;

interface Props {
  agent: Agent | undefined;
}

const ContentOxygenSupply = memo(({ agent }: Props) => {
  const { openModal } = useModalsContext();

  const { isWorking } = getEquipmentStatusDysfunctionsColor(
    agent?.equipmentStatus?.oxygenSupply?.status,
  );

  const remainingTimeValue = formatNumberWithUnit({
    number: isWorking ? agent?.sensors?.oxygenSupply?.value?.remaining_time : undefined,
    unit: AIR_SUPPLY_REMAINING_TIME_UNIT,
    decimals: DECIMALS,
  });

  const tankPressureValue = formatNumberWithUnit({
    number: isWorking ? agent?.sensors?.oxygenSupply?.value?.pressure : undefined,
    unit: AIR_SUPPLY_TANK_PRESSURE_UNIT,
    decimals: DECIMALS,
  });

  const temperatureValue = formatNumberWithUnit({
    number: isWorking ? agent?.sensors?.oxygenSupply?.value?.temp : undefined,
    unit: AIR_SUPPLY_TEMPERATURE_UNIT,
    decimals: DECIMALS,
  });

  const list = useMemo(
    () => [
      {
        labelText: i18n.t('carrierDetailsPopup.oxygenSupply.remainingTime'),
        valueText: remainingTimeValue,
        style: { opacity: remainingTimeValue === 'n/a' ? 0.4 : 1 },
        onClickLabel: () => {
          openModal({
            type: 'measurementGraph',
            measurementKind: 'airSupplyRemainingTime',
            title: `${i18n.t('carrierDetailsPopup.equipment.oxygenSupply')} (${i18n.t('carrierDetailsPopup.oxygenSupply.remainingTime').toLowerCase()})`,
            agentName: agent?.completeName || '',
            brainId: agent?.deviceName || '',
            carrierId: agent?.id || '',
          });
        },
      },
      {
        labelText: i18n.t('carrierDetailsPopup.oxygenSupply.tankPressure'),
        valueText: tankPressureValue,
        style: { opacity: tankPressureValue === 'n/a' ? 0.4 : 1 },
        onClickLabel: () => {
          openModal({
            type: 'measurementGraph',
            measurementKind: 'airSupplyPressure',
            title: `${i18n.t('carrierDetailsPopup.equipment.oxygenSupply')} (${i18n.t('carrierDetailsPopup.oxygenSupply.tankPressure').toLowerCase()})`,
            agentName: agent?.completeName || '',
            brainId: agent?.deviceName || '',
            carrierId: agent?.id || '',
          });
        },
      },
      {
        labelText: i18n.t('carrierDetailsPopup.oxygenSupply.temperature'),
        valueText: temperatureValue,
        style: { opacity: temperatureValue === 'n/a' ? 0.4 : 1 },
        onClickLabel: () => {
          openModal({
            type: 'measurementGraph',
            measurementKind: 'airSupplyTemperature',
            title: `${i18n.t('carrierDetailsPopup.equipment.oxygenSupply')} (${i18n.t('carrierDetailsPopup.oxygenSupply.temperature').toLowerCase()})`,
            agentName: agent?.completeName || '',
            brainId: agent?.deviceName || '',
            carrierId: agent?.id || '',
          });
        },
      },
    ],
    [
      agent?.completeName,
      agent?.deviceName,
      agent?.id,
      remainingTimeValue,
      tankPressureValue,
      temperatureValue,
      openModal,
    ],
  );

  if (!agent) {
    return null;
  }

  return <SectionList elements={list} />;
});

ContentOxygenSupply.displayName = 'ContentOxygenSupply';

export default ContentOxygenSupply;
