import SaveOutlined from '@ant-design/icons/SaveOutlined';
import { Button, List } from 'antd';
import { memo, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import useCompanyMobileAppFeatures, {
  type MobileAppFeature,
  type MobileAppFeatureSchema,
} from '~/hooks/useCompanyMobileAppFeatures';
import i18n from '~/locales/i18n';
import notification from '~/utils/notification';

import AppConfigFormItem from './components/AppConfigFormItem';

const BottomButtonsDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 28px;
`;

const AppFeaturesForm = memo(() => {
  const { mobileAppFeatures, setMobileAppFeatures, isSetMobileAppFeaturesLoading } =
    useCompanyMobileAppFeatures();

  const [editedAppSchema, setEditedAppSchema] = useState<MobileAppFeatureSchema>(mobileAppFeatures);

  useEffect(() => {
    setEditedAppSchema(mobileAppFeatures);
  }, [mobileAppFeatures]);

  const toggleFeature = useCallback((feature: MobileAppFeature) => {
    setEditedAppSchema((prev) => ({
      ...prev,
      features: prev.features.map((f) =>
        f.type === feature.type ? { ...f, isEnabled: !f.isEnabled } : f,
      ),
    }));
  }, []);

  const setPropertyValue = useCallback(
    (featureType: string, propertyKey: string, value: string) => {
      setEditedAppSchema((prev) => {
        const updatedFeatures = prev.features.map((f) => {
          if (f.type === featureType && 'properties' in f) {
            return {
              ...f,
              properties: {
                ...f.properties,
                [propertyKey]: value,
              },
            } as MobileAppFeature;
          }
          return f;
        });

        return {
          ...prev,
          features: updatedFeatures,
        };
      });
    },
    [],
  );

  return (
    <>
      <List
        itemLayout="horizontal"
        dataSource={editedAppSchema.features}
        renderItem={(feature) => (
          <List.Item key={feature.type}>
            <AppConfigFormItem
              feature={feature}
              toggleFeature={toggleFeature}
              setPropertyValue={setPropertyValue}
              isLoading={isSetMobileAppFeaturesLoading}
            />
          </List.Item>
        )}
      />
      <BottomButtonsDiv>
        <Button
          size="middle"
          type="primary"
          loading={isSetMobileAppFeaturesLoading}
          icon={<SaveOutlined />}
          onClick={async () => {
            try {
              await setMobileAppFeatures(editedAppSchema);
              notification.success({
                message: i18n.t('common.success'),
                description: i18n.t('mobileAppFeatures.savedSuccessfully'),
              });
            } catch (error) {
              notification.error({
                message: i18n.t('common.error'),
                description: (error as any)?.message,
              });
            }
          }}
        >
          {i18n.t('common.save')}
        </Button>
      </BottomButtonsDiv>
    </>
  );
});

AppFeaturesForm.displayName = 'AppFeaturesForm';

export default AppFeaturesForm;
