import { Tooltip } from 'antd';
import { memo, useMemo } from 'react';
import styled from 'styled-components';

import TimeAgo from '~/components/TimeAgo';
import useAlarmsContext from '~/context/useAlarmsContext';
import i18n from '~/locales/i18n';
import theme from '~/theme';
import { AGENT_STATUS, type Agent } from '~/types/agent';
import getAgentStatusColor from '~/utils/agent/getAgentStatusColor';
import getAlarmColorFromLevel from '~/utils/alarm/getAlarmColorFromLevel';
import getHighestAlarmLevel from '~/utils/alarm/getHighestAlarmLevel';
import { formatDateTime } from '~/utils/dateTime';
import curateUrl from '~/utils/parse/curateUrl';

const BadgeDiv = styled.div`
  border: none;
  border-radius: 16px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem 1rem;
`;

const AlarmIconImg = styled.img`
  height: 14px;
  transform: translateY(-1px);
`;

const H4 = styled.h4`
  margin: 0;
  font-size: 14px;
  line-height: 1;
  color: ${theme.colors.white};
`;

const TooltipDiv = styled.div`
  font-size: 14px;
  font-weight: 500;
`;

const TooltipSmall = styled.small`
  font-size: 12px;
`;

interface Props {
  agent: Agent | undefined;
}

const StatusBadge = memo(({ agent }: Props) => {
  const { ongoingAlarms } = useAlarmsContext();

  const hasAlarm = agent?.status === AGENT_STATUS.alert;

  const status = agent?.status ?? AGENT_STATUS.connectionLost;
  const isOffline = agent?.isOffline;

  const agentAlarms = useMemo(
    () => ongoingAlarms.filter((alarm) => alarm.carrier.id === agent?.id),
    [agent, ongoingAlarms],
  );

  const highestLevelAlarm = useMemo(() => getHighestAlarmLevel(agentAlarms), [agentAlarms]);

  const backgroundColor = highestLevelAlarm
    ? getAlarmColorFromLevel(highestLevelAlarm).text
    : getAgentStatusColor(agent);

  return (
    <Tooltip
      placement="right"
      title={
        <TooltipDiv>
          {i18n.t('common.lastUpdate')}:{' '}
          {agent?.lastUpdate ? <TimeAgo date={agent.lastUpdate} /> : 'n/a'}
          {agent?.lastUpdate ? (
            <>
              <br />
              <TooltipSmall>{formatDateTime(agent.lastUpdate)}</TooltipSmall>
            </>
          ) : null}
        </TooltipDiv>
      }
    >
      <BadgeDiv style={{ backgroundColor, cursor: 'help' }}>
        {hasAlarm && <AlarmIconImg src={curateUrl('/icons/alert/alert-small-white.png')} alt="" />}
        <H4 data-id="status-badge">
          {i18n.t(
            `general.agentStatus.${isOffline && status !== AGENT_STATUS.alert ? 'offline' : status}`,
          )}
        </H4>
      </BadgeDiv>
    </Tooltip>
  );
});

StatusBadge.displayName = 'StatusBadge';

export default StatusBadge;
