import SaveOutlined from '@ant-design/icons/SaveOutlined';
import { Input, Button } from 'antd';
import { memo, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import useCompanyMobileAppFeatures from '~/hooks/useCompanyMobileAppFeatures';
import i18n from '~/locales/i18n';
import theme from '~/theme';
import notification from '~/utils/notification';

const BottomButtonsDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 16px;
`;

const AppFeaturesJSON = memo(() => {
  const { configurationValue, setMobileAppFeatures, isSetMobileAppFeaturesLoading } =
    useCompanyMobileAppFeatures();

  const [textAreaValue, setTextAreaValue] = useState<string>(configurationValue);

  useEffect(() => {
    setTextAreaValue(configurationValue);
  }, [configurationValue]);

  const hasError = useMemo(() => {
    try {
      JSON.parse(textAreaValue);
      return false;
    } catch (error) {
      return true;
    }
  }, [textAreaValue]);

  return (
    <>
      <Input.TextArea
        disabled={isSetMobileAppFeaturesLoading}
        style={{
          fontFamily: 'monospace',
          background: theme.colors.lightGrey,
          borderColor: hasError ? theme.colors.red : theme.colors.darkGrey,
        }}
        value={textAreaValue}
        onChange={(event) => {
          setTextAreaValue(event.target.value);
        }}
        autoSize
      />
      <BottomButtonsDiv>
        <Button
          size="middle"
          type="primary"
          loading={isSetMobileAppFeaturesLoading}
          icon={<SaveOutlined />}
          onClick={async () => {
            try {
              if (hasError) {
                notification.error({
                  message: i18n.t('common.error'),
                  description: i18n.t('mobileAppFeatures.invalidJsonFormat'),
                });
                return;
              }
              await setMobileAppFeatures(JSON.parse(textAreaValue));
              notification.success({
                message: i18n.t('common.success'),
                description: i18n.t('mobileAppFeatures.savedSuccessfully'),
              });
            } catch (error) {
              notification.error({
                message: i18n.t('common.error'),
                description: (error as any)?.message,
              });
            }
          }}
        >
          {i18n.t('common.save')}
        </Button>
      </BottomButtonsDiv>
    </>
  );
});

AppFeaturesJSON.displayName = 'AppFeaturesJSON';

export default AppFeaturesJSON;
