import type { Indicator } from '~/types/indicator';
import {
  GREEN_COLOR,
  RED_COLOR,
  YELLOW_COLOR,
} from '~/utils/indicators/getRiskLevelLetterAndColor';

export default function getIndicatorLevelColor(indicatorValue: Indicator['value']): string {
  const percent = indicatorValue * 10;

  if (percent < 33) {
    return GREEN_COLOR;
  }
  if (percent < 66) {
    return YELLOW_COLOR;
  }
  return RED_COLOR;
}
