import { useQuery, type ApolloError } from '@apollo/client';
import { gql } from '@apollo/client';
import orderBy from 'lodash/orderBy';
import { useCallback, useMemo } from 'react';

import i18n from '~/locales/i18n';
import type { VideoRecordingV1 } from '~/types/videoRecording';
import logger from '~/utils/logger';
import notification from '~/utils/notification';

interface CarrierVideoRecordingsV1QueryVariables {
  carrierId: string;
  startAfter: string | null;
  startBefore: string | null;
  limit: number;
  nextToken: string | null;
}

interface CarrierVideoRecordingsV1QueryData {
  carrier: {
    __typename: 'Carrier_Cognito';
    id: string;
    video_recordings: {
      __typename: 'VideoRecordingConnection';
      nextToken: string | null;
      items: VideoRecordingV1[];
    };
  };
}

export default function useQueryCarrierVideoRecordingsV1({
  agentId,
  startAfter,
  startBefore,
}: {
  agentId: string;
  startAfter: string | null;
  startBefore: string | null;
}): {
  videoRecordingsV1: VideoRecordingV1[];
  videoRecordingsV1NextToken: string | null;
  isLoading: boolean;
  error: ApolloError | undefined;
  refetchVideoRecordingsV1: () => void;
  fetchNextPageV1: (nextToken: string | null) => void;
} {
  const { fetchMore, loading, error, data, refetch } = useQuery<
    CarrierVideoRecordingsV1QueryData,
    CarrierVideoRecordingsV1QueryVariables
  >(
    gql`
      query QueryCarrierVideoRecordingsV1(
        $carrierId: ID!
        $startAfter: AWSDateTime
        $startBefore: AWSDateTime
        $limit: Int
        $nextToken: String
      ) {
        carrier(id: $carrierId) {
          id
          video_recordings(
            startAfter: $startAfter
            startBefore: $startBefore
            limit: $limit
            nextToken: $nextToken
          ) {
            nextToken
            items {
              duration_ms
              id
              start_recording
              view_url
            }
          }
        }
      }
    `,
    {
      variables: {
        carrierId: agentId,
        startAfter,
        startBefore,
        limit: 25,
        nextToken: null,
      },
      fetchPolicy: 'network-only',
      skip: !agentId,
    },
  );

  const fetchNextPageV1 = useCallback(
    (nextToken: string | null) => {
      fetchMore({
        variables: {
          nextToken,
        },
        updateQuery: (previousResult, { fetchMoreResult }) => ({
          ...previousResult,
          carrier: {
            ...previousResult.carrier,
            video_recordings: {
              ...previousResult.carrier?.video_recordings,
              // Merge duplicates and sort by date
              items: orderBy(
                Object.values(
                  [
                    ...(previousResult.carrier?.video_recordings?.items || []),
                    ...(fetchMoreResult.carrier?.video_recordings?.items || []),
                  ].reduce(
                    (acc, cur) => ({
                      ...acc,
                      [cur.id]: cur,
                    }),
                    {},
                  ),
                ),
                ['start_recording'],
                ['desc'],
              ),
              nextToken: fetchMoreResult.carrier?.video_recordings?.nextToken,
            },
          },
        }),
      }).catch((e) => {
        logger.error('useQueryCarrierVideoRecordingsV1: error', e);
        notification.error({
          message: i18n.t('general.notifications.fetchDataErrorTitle'),
          description: i18n.t('general.notifications.fetchDataErrorDescription'),
        });
      });
    },
    [fetchMore],
  );

  return useMemo(
    () => ({
      videoRecordingsV1: (data?.carrier?.video_recordings?.items || []).filter(
        (item) => !!item.duration_ms && !!item.view_url,
      ),
      videoRecordingsV1NextToken: data?.carrier?.video_recordings?.nextToken || null,
      isLoading: loading,
      error,
      refetchVideoRecordingsV1: refetch,
      fetchNextPageV1,
    }),
    [
      data?.carrier?.video_recordings?.items,
      data?.carrier?.video_recordings?.nextToken,
      loading,
      error,
      refetch,
      fetchNextPageV1,
    ],
  );
}
