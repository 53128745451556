import { useQuery, type ApolloError } from '@apollo/client';
import { gql } from '@apollo/client';
import { useMemo } from 'react';

import type { VideoRecordingV2 } from '~/types/videoRecording';

interface VideoRecordingV2QueryVariables {
  videoRecordingId: string;
}

interface VideoRecordingV2QueryData {
  video_recording_v2: VideoRecordingV2;
}

export default function useQueryVideoRecordingV2({
  videoRecordingId,
  skip,
}: {
  videoRecordingId: string | undefined;
  skip: boolean;
}): {
  videoRecordingV2?: VideoRecordingV2QueryData['video_recording_v2'];
  isLoading: boolean;
  error?: ApolloError;
} {
  const { data, loading, error } = useQuery<
    VideoRecordingV2QueryData,
    VideoRecordingV2QueryVariables
  >(
    gql`
      query QueryVideoRecordingV2($videoRecordingId: ID!) {
        video_recording_v2(id: $videoRecordingId) {
          id
          start_recording
          duration_ms
          view_url
        }
      }
    `,
    {
      variables: {
        videoRecordingId: videoRecordingId || '',
      },
      skip: skip || !videoRecordingId,
    },
  );

  return useMemo(
    () => ({
      videoRecordingV2: data?.video_recording_v2,
      isLoading: loading,
      error,
    }),
    [data?.video_recording_v2, loading, error],
  );
}
