import { ApolloError } from '@apollo/client';
import { Alert, Empty, Modal, Skeleton, Tag } from 'antd';
import { memo, useMemo } from 'react';
import { Chart } from 'react-google-charts';

import { MeasurementDataPoint } from '~/apollo/hooks/measurements/types';
import useQueryActivityLevelMeasurementHistory from '~/apollo/hooks/measurements/useQueryActivityLevelMeasurementHistory';
import useQueryBatteryMeasurementHistory from '~/apollo/hooks/measurements/useQueryBatteryMeasurementHistory';
import useQueryBodyMultiSensorV1MeasurementHistory from '~/apollo/hooks/measurements/useQueryBodyMultiSensorV1MeasurementHistory';
import useQueryGasMeasurementHistory from '~/apollo/hooks/measurements/useQueryGasMeasurementHistory';
import useQueryHeartRateMeasurementHistory from '~/apollo/hooks/measurements/useQueryHeartRateMeasurementHistory';
import useQueryLteSignalStrengthMeasurementHistory from '~/apollo/hooks/measurements/useQueryLteSignalStrengthMeasurementHistory';
import useQueryOxygenSupplyMeasurementHistory from '~/apollo/hooks/measurements/useQueryOxygenSupplyMeasurementHistory';
import useQueryRadiationMeasurementHistory from '~/apollo/hooks/measurements/useQueryRadiationMeasurementHistory';
import {
  ACTIVITY_LEVEL_UNIT,
  AIR_SUPPLY_REMAINING_TIME_UNIT,
  AIR_SUPPLY_TANK_PRESSURE_UNIT,
  AIR_SUPPLY_TEMPERATURE_UNIT,
  BATTERY_CHARGING_UNIT,
  BATTERY_HEALTH_UNIT,
  BATTERY_LEVEL_UNIT,
  BATTERY_TEMPERATURE_UNIT,
  BATTERY_VOLTAGE_UNIT,
  GAS_CH4_HC_UNIT,
  GAS_CO2_UNIT,
  GAS_CO_UNIT,
  GAS_H2S_UNIT,
  GAS_O2_UNIT,
  HEART_RATE_UNIT,
  LTE_BARS_UNIT,
  LTE_DATA_TRANSMISSION_UNIT,
  LTE_NETWORK_CONNECTIVITY_UNIT,
  PHYSIOLOGICAL_TEMPERATURE_UNIT,
  RADIATION_UNIT,
} from '~/config/units';
import i18n from '~/locales/i18n';
import type { ModalProps } from '~/types/modal';

export interface MeasurementGraphModalProps {
  measurementKind:
    | 'heartRate'
    | 'skinTemperature'
    | 'bodyTemperature'
    | 'activityLevel'
    | 'airSupplyRemainingTime'
    | 'airSupplyPressure'
    | 'airSupplyTemperature'
    | 'radiationGamma'
    | 'radiationBackground'
    | 'gasCh4Hc'
    | 'gasCo'
    | 'gasCo2'
    | 'gasH2s'
    | 'gasO2'
    | 'batteryLevel'
    | 'batteryHealth'
    | 'batteryCharging'
    | 'batteryVoltage'
    | 'batteryTemperature'
    | 'lteNetworkConnectivity'
    | 'lteDataTransmission'
    | 'lteBars';
  title: string;
  agentName: string;
  brainId: string;
  carrierId: string;
}

const MeasurementGraphModal = memo(
  ({
    isOpen,
    onClose,
    measurementKind,
    title,
    agentName,
    brainId,
    carrierId,
  }: ModalProps & MeasurementGraphModalProps) => {
    const shouldSkip = !isOpen || !carrierId;

    const {
      heartRateDataPoints,
      isLoading: isHeartRateLoading,
      error: heartRateError,
    } = useQueryHeartRateMeasurementHistory({
      carrierId,
      skip: shouldSkip || measurementKind !== 'heartRate',
    });

    const {
      bodyTemperatureDataPoints,
      skinTemperatureDataPoints,
      isLoading: isBodyMultiSensorV1Loading,
      error: bodyMultiSensorV1Error,
    } = useQueryBodyMultiSensorV1MeasurementHistory({
      carrierId,
      skip:
        shouldSkip ||
        (measurementKind !== 'bodyTemperature' && measurementKind !== 'skinTemperature'),
    });

    const {
      activityLevelDataPoints,
      isLoading: isActivityLevelLoading,
      error: activityLevelError,
    } = useQueryActivityLevelMeasurementHistory({
      carrierId,
      skip: shouldSkip || measurementKind !== 'activityLevel',
    });

    const {
      pressureDataPoints,
      remainingTimeDataPoints,
      temperatureDataPoints,
      isLoading: isOxygenSupplyLoading,
      error: oxygenSupplyError,
    } = useQueryOxygenSupplyMeasurementHistory({
      carrierId,
      skip:
        shouldSkip ||
        (measurementKind !== 'airSupplyRemainingTime' &&
          measurementKind !== 'airSupplyPressure' &&
          measurementKind !== 'airSupplyTemperature'),
    });

    const {
      gammaDataPoints,
      backgroundDataPoints,
      isLoading: isRadiationLoading,
      error: radiationError,
    } = useQueryRadiationMeasurementHistory({
      carrierId,
      skip:
        shouldSkip ||
        (measurementKind !== 'radiationGamma' && measurementKind !== 'radiationBackground'),
    });

    const {
      ch4HcDataPoints,
      coDataPoints,
      co2DataPoints,
      h2sDataPoints,
      o2DataPoints,
      isLoading: isGasLoading,
      error: gasError,
    } = useQueryGasMeasurementHistory({
      carrierId,
      skip:
        shouldSkip ||
        (measurementKind !== 'gasCh4Hc' &&
          measurementKind !== 'gasCo' &&
          measurementKind !== 'gasCo2' &&
          measurementKind !== 'gasH2s' &&
          measurementKind !== 'gasO2'),
    });

    const {
      batteryLevelDataPoints,
      batteryHealthDataPoints,
      batteryChargingDataPoints,
      batteryVoltageDataPoints,
      batteryTemperatureDataPoints,
      isLoading: isBatteryLoading,
      error: batteryError,
    } = useQueryBatteryMeasurementHistory({
      carrierId,
      skip:
        shouldSkip ||
        (measurementKind !== 'batteryLevel' &&
          measurementKind !== 'batteryHealth' &&
          measurementKind !== 'batteryCharging' &&
          measurementKind !== 'batteryVoltage' &&
          measurementKind !== 'batteryTemperature'),
    });

    const {
      lteNetworkConnectivityDataPoints,
      lteDataTransmissionDataPoints,
      lteBarsDataPoints,
      isLoading: isLteSignalStrengthLoading,
      error: lteSignalStrengthError,
    } = useQueryLteSignalStrengthMeasurementHistory({
      carrierId,
      skip:
        shouldSkip ||
        (measurementKind !== 'lteNetworkConnectivity' &&
          measurementKind !== 'lteDataTransmission' &&
          measurementKind !== 'lteBars'),
    });

    const activeMeasurement: {
      dataPoints: MeasurementDataPoint[];
      title: string;
      unit: string;
      isLoading: boolean;
      error: ApolloError | undefined;
    } = useMemo(() => {
      switch (measurementKind) {
        case 'heartRate':
          return {
            dataPoints: heartRateDataPoints,
            title: i18n.t('carrierDetailsPopup.physiologicalSensors.heartRate'),
            unit: HEART_RATE_UNIT,
            isLoading: isHeartRateLoading,
            error: heartRateError,
          };
        case 'skinTemperature':
          return {
            dataPoints: skinTemperatureDataPoints,
            title: i18n.t('carrierDetailsPopup.physiologicalSensors.skinTemperature'),
            unit: PHYSIOLOGICAL_TEMPERATURE_UNIT,
            isLoading: isBodyMultiSensorV1Loading,
            error: bodyMultiSensorV1Error,
          };
        case 'bodyTemperature':
          return {
            dataPoints: bodyTemperatureDataPoints,
            title: i18n.t('carrierDetailsPopup.physiologicalSensors.bodyTemperature'),
            unit: PHYSIOLOGICAL_TEMPERATURE_UNIT,
            isLoading: isBodyMultiSensorV1Loading,
            error: bodyMultiSensorV1Error,
          };
        case 'activityLevel':
          return {
            dataPoints: activityLevelDataPoints,
            title: i18n.t('carrierDetailsPopup.physiologicalSensors.activityLevel'),
            unit: ACTIVITY_LEVEL_UNIT,
            isLoading: isActivityLevelLoading,
            error: activityLevelError,
          };
        case 'airSupplyRemainingTime':
          return {
            dataPoints: remainingTimeDataPoints,
            title: i18n.t('carrierDetailsPopup.oxygenSupply.remainingTime'),
            unit: AIR_SUPPLY_REMAINING_TIME_UNIT,
            isLoading: isOxygenSupplyLoading,
            error: oxygenSupplyError,
          };
        case 'airSupplyPressure':
          return {
            dataPoints: pressureDataPoints,
            title: i18n.t('carrierDetailsPopup.oxygenSupply.tankPressure'),
            unit: AIR_SUPPLY_TANK_PRESSURE_UNIT,
            isLoading: isOxygenSupplyLoading,
            error: oxygenSupplyError,
          };
        case 'airSupplyTemperature':
          return {
            dataPoints: temperatureDataPoints,
            title: i18n.t('carrierDetailsPopup.oxygenSupply.temperature'),
            unit: AIR_SUPPLY_TEMPERATURE_UNIT,
            isLoading: isOxygenSupplyLoading,
            error: oxygenSupplyError,
          };
        case 'radiationGamma':
          return {
            dataPoints: gammaDataPoints,
            title: i18n.t('carrierDetailsPopup.radiation.gamma'),
            unit: RADIATION_UNIT,
            isLoading: isRadiationLoading,
            error: radiationError,
          };
        case 'radiationBackground':
          return {
            dataPoints: backgroundDataPoints,
            title: i18n.t('carrierDetailsPopup.radiation.background'),
            unit: RADIATION_UNIT,
            isLoading: isRadiationLoading,
            error: radiationError,
          };
        case 'gasCh4Hc':
          return {
            dataPoints: ch4HcDataPoints,
            title: i18n.t('carrierDetailsPopup.gasSensors.ch4/hc'),
            unit: GAS_CH4_HC_UNIT,
            isLoading: isGasLoading,
            error: gasError,
          };
        case 'gasCo':
          return {
            dataPoints: coDataPoints,
            title: i18n.t('carrierDetailsPopup.gasSensors.co'),
            unit: GAS_CO_UNIT,
            isLoading: isGasLoading,
            error: gasError,
          };
        case 'gasCo2':
          return {
            dataPoints: co2DataPoints,
            title: i18n.t('carrierDetailsPopup.gasSensors.co2'),
            unit: GAS_CO2_UNIT,
            isLoading: isGasLoading,
            error: gasError,
          };
        case 'gasH2s':
          return {
            dataPoints: h2sDataPoints,
            title: i18n.t('carrierDetailsPopup.gasSensors.h2s'),
            unit: GAS_H2S_UNIT,
            isLoading: isGasLoading,
            error: gasError,
          };
        case 'gasO2':
          return {
            dataPoints: o2DataPoints,
            title: i18n.t('carrierDetailsPopup.gasSensors.o2'),
            unit: GAS_O2_UNIT,
            isLoading: isGasLoading,
            error: gasError,
          };
        case 'batteryLevel':
          return {
            dataPoints: batteryLevelDataPoints,
            title: i18n.t('carrierDetailsPopup.battery.level'),
            unit: BATTERY_LEVEL_UNIT,
            isLoading: isBatteryLoading,
            error: batteryError,
          };
        case 'batteryHealth':
          return {
            dataPoints: batteryHealthDataPoints,
            title: i18n.t('carrierDetailsPopup.battery.health'),
            unit: BATTERY_HEALTH_UNIT,
            isLoading: isBatteryLoading,
            error: batteryError,
          };
        case 'batteryCharging':
          return {
            dataPoints: batteryChargingDataPoints,
            title: i18n.t('carrierDetailsPopup.battery.charging'),
            unit: BATTERY_CHARGING_UNIT,
            isLoading: isBatteryLoading,
            error: batteryError,
          };
        case 'batteryVoltage':
          return {
            dataPoints: batteryVoltageDataPoints,
            title: i18n.t('carrierDetailsPopup.battery.voltage'),
            unit: BATTERY_VOLTAGE_UNIT,
            isLoading: isBatteryLoading,
            error: batteryError,
          };
        case 'batteryTemperature':
          return {
            dataPoints: batteryTemperatureDataPoints,
            title: i18n.t('carrierDetailsPopup.battery.temperature'),
            unit: BATTERY_TEMPERATURE_UNIT,
            isLoading: isBatteryLoading,
            error: batteryError,
          };
        case 'lteNetworkConnectivity':
          return {
            dataPoints: lteNetworkConnectivityDataPoints,
            title: i18n.t('carrierDetailsPopup.lte.networkConnectivity'),
            unit: LTE_NETWORK_CONNECTIVITY_UNIT,
            isLoading: isLteSignalStrengthLoading,
            error: lteSignalStrengthError,
          };
        case 'lteDataTransmission':
          return {
            dataPoints: lteDataTransmissionDataPoints,
            title: i18n.t('carrierDetailsPopup.lte.dataTransmission'),
            unit: LTE_DATA_TRANSMISSION_UNIT,
            isLoading: isLteSignalStrengthLoading,
            error: lteSignalStrengthError,
          };
        case 'lteBars':
          return {
            dataPoints: lteBarsDataPoints,
            title: i18n.t('carrierDetailsPopup.lte.bars'),
            unit: LTE_BARS_UNIT,
            isLoading: isLteSignalStrengthLoading,
            error: lteSignalStrengthError,
          };
        default:
          return {
            dataPoints: [],
            title: 'Unknown',
            unit: 'Unknown',
            isLoading: false,
            error: undefined,
          };
      }
    }, [
      activityLevelDataPoints,
      activityLevelError,
      backgroundDataPoints,
      batteryChargingDataPoints,
      batteryError,
      batteryHealthDataPoints,
      batteryLevelDataPoints,
      batteryTemperatureDataPoints,
      batteryVoltageDataPoints,
      bodyMultiSensorV1Error,
      bodyTemperatureDataPoints,
      ch4HcDataPoints,
      co2DataPoints,
      coDataPoints,
      gammaDataPoints,
      gasError,
      h2sDataPoints,
      heartRateDataPoints,
      heartRateError,
      isActivityLevelLoading,
      isBatteryLoading,
      isBodyMultiSensorV1Loading,
      isGasLoading,
      isHeartRateLoading,
      isLteSignalStrengthLoading,
      isOxygenSupplyLoading,
      isRadiationLoading,
      lteBarsDataPoints,
      lteDataTransmissionDataPoints,
      lteNetworkConnectivityDataPoints,
      lteSignalStrengthError,
      measurementKind,
      o2DataPoints,
      oxygenSupplyError,
      pressureDataPoints,
      radiationError,
      remainingTimeDataPoints,
      skinTemperatureDataPoints,
      temperatureDataPoints,
    ]);

    const chartData = useMemo(() => {
      if (!activeMeasurement.dataPoints) {
        return [];
      }

      return [
        ['Time', activeMeasurement.title],
        ...activeMeasurement.dataPoints.map((measurement) => [
          new Date(measurement.timestamp),
          measurement.value,
        ]),
      ];
    }, [activeMeasurement.dataPoints, activeMeasurement.title]);

    return (
      <Modal title={title} footer={null} centered width={800} open={isOpen} onCancel={onClose}>
        {(agentName || brainId) && (
          <div style={{ display: 'flex', gap: '2px' }}>
            {agentName && <Tag color="blue">{agentName}</Tag>}
            {brainId && <Tag color="default">{brainId}</Tag>}
          </div>
        )}
        {activeMeasurement.isLoading && (
          <div>
            <br />
            <Skeleton active />
            <br />
            {i18n.t('common.loading')}
          </div>
        )}
        {activeMeasurement.error && (
          <Alert
            message="Error loading measurements"
            type="warning"
            style={{ marginTop: '12px' }}
          />
        )}
        {!activeMeasurement.isLoading && activeMeasurement.dataPoints.length > 0 && (
          <Chart
            chartType="LineChart"
            width="100%"
            height="400px"
            data={chartData}
            options={{
              title: activeMeasurement.title,
              curveType: 'function',
              legend: { position: 'bottom' },
              hAxis: {
                title: 'Time',
                format: 'dd/MM/yyyy HH:mm:ss',
                gridlines: {
                  count: -1,
                  units: {
                    days: { format: ['dd/MM/yyyy'] },
                    hours: { format: ['HH:mm'] },
                    minutes: { format: ['HH:mm'] },
                  },
                },
              },
              vAxis: {
                title: activeMeasurement.unit,
              },
              explorer: {
                actions: ['dragToZoom', 'rightClickToReset'],
                axis: 'horizontal',
                keepInBounds: true,
                maxZoomIn: 0.01,
              },
            }}
          />
        )}
        {!activeMeasurement.isLoading && activeMeasurement.dataPoints.length === 0 && (
          <div style={{ marginTop: '3rem', marginBottom: '2rem' }}>
            <Empty description={i18n.t('common.noData')} />
          </div>
        )}
      </Modal>
    );
  },
);

MeasurementGraphModal.displayName = 'MeasurementGraphModal';

export default MeasurementGraphModal;
