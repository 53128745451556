import CloseOutlined from '@ant-design/icons/CloseOutlined';
import { Button, DatePicker } from 'antd';
import dayjs from 'dayjs';
import { memo } from 'react';

import i18n from '~/locales/i18n';
import { formatDateTime } from '~/utils/dateTime';

interface Props {
  activeDateRange: [string, string];
  updateActiveDateRange: (dateRange: [string, string]) => void;
}

const VideoRecordingsDatePicker = memo(({ activeDateRange, updateActiveDateRange }: Props) => (
  <DatePicker.RangePicker
    style={{ width: '100%' }}
    value={
      activeDateRange[0] && activeDateRange[1]
        ? [
            dayjs(activeDateRange[0], 'YYYY-MM-DD HH:mm:ss'),
            dayjs(activeDateRange[1], 'YYYY-MM-DD HH:mm:ss'),
          ]
        : undefined
    }
    size="middle"
    variant="borderless"
    onCalendarChange={(_, dateRange) => {
      if (dateRange[0] && dateRange[1]) {
        updateActiveDateRange([
          `${dateRange[0].substring(0, 10)} 00:00:00`,
          `${dateRange[1].substring(0, 10)} 23:59:59`,
        ]);
      }
      if (!dateRange[0] && !dateRange[1]) {
        updateActiveDateRange(['', '']);
      }
    }}
    allowClear
    disabledDate={(current) => current && current > dayjs().endOf('day')}
    format={(value) => formatDateTime(value.toISOString(), 'standard')}
    renderExtraFooter={
      activeDateRange[0] && activeDateRange[1]
        ? () => (
            <Button
              type="primary"
              size="small"
              icon={<CloseOutlined />}
              onClick={() => {
                updateActiveDateRange(['', '']);
              }}
            >
              {i18n.t('common.clear')}
            </Button>
          )
        : undefined
    }
  />
));

VideoRecordingsDatePicker.displayName = 'VideoRecordingsDatePicker';

export default VideoRecordingsDatePicker;
