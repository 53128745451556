export enum EQUIPMENT_STATUS {
  no_error = 'no_error',
  no_status = 'no_status',
  sensor_unpaired = 'sensor_unpaired',
  phone_ble_disabled = 'phone_ble_disabled',
  ble_disabled = 'ble_disabled',
  sensor_disconnected = 'sensor_disconnected',
  sensor_connecting = 'sensor_connecting',
  no_sensor = 'no_sensor',
  sensor_error = 'sensor_error',
  sensor_inactive = 'sensor_inactive',
  sensor_data_error = 'sensor_data_error',
  sensor_broken = 'sensor_broken',
}

export type EquipmentState = 'ok' | 'regular' | 'dysfunction';

export interface EquipmentStatusDetails {
  status: EQUIPMENT_STATUS;
  hasDysfunction: boolean;
}

export interface EquipmentStatusMap {
  emergencyButton: EquipmentStatusDetails;
  heartRate: EquipmentStatusDetails;
  physiologicalTemperature: EquipmentStatusDetails;
  activityLevel: EquipmentStatusDetails;
  gas: EquipmentStatusDetails;
  radiation: EquipmentStatusDetails;
  oxygenSupply: EquipmentStatusDetails;
  battery: EquipmentStatusDetails;
  lteSignalStrength: EquipmentStatusDetails;
  traakFront: EquipmentStatusDetails;
  traakBack: EquipmentStatusDetails;
}

export type EquipmentStatusKeys = keyof EquipmentStatusMap;
