import { gql, useQuery, type ApolloError } from '@apollo/client';
import { useMemo } from 'react';

import { MEASUREMENT_ITEMS_QUERY_BODY } from '~/apollo/operations/shared';
import { SENSOR_NAME_QUERY, SENSOR_NAME_VARIABLE, type HeartRateSensorType } from '~/types/sensor';

import type { MeasurementDataPoint } from './types';

interface HeartRateMeasurementHistoryQueryVariables {
  carrierId: string;
}

interface HeartRateMeasurementHistoryQueryData {
  carrier: {
    device: {
      __typename?: 'Device';
      heartRate: {
        __typename?: 'MeasurementConnection';
        items: HeartRateSensorType[];
      };
    };
  };
}

export default function useQueryHeartRateMeasurementHistory({
  carrierId,
  skip,
}: {
  carrierId: string;
  skip: boolean;
}): {
  heartRateDataPoints: MeasurementDataPoint[];
  isLoading: boolean;
  error?: ApolloError;
  refetch: () => Promise<void>;
} {
  const { loading, error, data, refetch } = useQuery<
    HeartRateMeasurementHistoryQueryData,
    HeartRateMeasurementHistoryQueryVariables
  >(
    gql`
    query QueryHeartRateMeasurementHistory($carrierId: ID!) {
        carrier(id: $carrierId) {
            device {
              ${SENSOR_NAME_VARIABLE.heartRate}: measurements(type: "${SENSOR_NAME_QUERY.heart_rate}", limit: 1000) ${MEASUREMENT_ITEMS_QUERY_BODY}
            }
          }
      }
    
  `,
    {
      variables: { carrierId },
      fetchPolicy: 'no-cache',
      skip,
    },
  );

  return useMemo(
    () => ({
      heartRateDataPoints: (data?.carrier?.device?.heartRate?.items || [])
        .map((item) => ({
          value: parseFloat(`${item.value}`),
          timestamp: item.timestamp,
        }))
        .filter((dataPoint) => Number.isFinite(dataPoint.value)),
      isLoading: loading,
      error,
      refetch: async () => {
        await refetch();
      },
    }),
    [data?.carrier?.device?.heartRate?.items, loading, error, refetch],
  );
}
