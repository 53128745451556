import { Select, Switch, Input, Tooltip } from 'antd';
import { memo } from 'react';
import styled from 'styled-components';

import type {
  AppConfigBoolean,
  AppConfigLocationSource,
  AppConfigVideoStreamType,
  MobileAppFeature,
} from '~/hooks/useCompanyMobileAppFeatures';
import i18n from '~/locales/i18n';
import theme from '~/theme';

const ItemWrapperDiv = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: minmax(0, auto) minmax(0, 1fr);
  grid-gap: 12px;
  align-items: center;
  overflow-x: auto;
`;

const ItemPropertiesDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const PropertyDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const PropertyLabelSpan = styled.span`
  color: ${theme.colors.darkGrey};
  white-space: nowrap;
`;

const videoStreamTypeOptions: {
  value: AppConfigVideoStreamType;
  label: string;
}[] = [
  { value: 'IVS_LOW_LATENCY', label: 'IVS Low Latency' },
  { value: 'IVS_REALTIME', label: 'IVS Realtime' },
  { value: 'KVS', label: 'KVS' },
];

const locationSourceOptions: {
  value: AppConfigLocationSource;
  label: string;
}[] = [
  { value: 'PlayServices', label: 'Play Services' },
  { value: 'LocationManager', label: 'Location Manager' },
];

const booleanOptions: {
  value: AppConfigBoolean;
  label: string;
}[] = [
  { value: 'true', label: i18n.t('common.yes') },
  { value: 'false', label: i18n.t('common.no') },
];

interface Props {
  feature: MobileAppFeature;
  toggleFeature: (feature: MobileAppFeature) => void;
  setPropertyValue: (featureType: string, propertyKey: string, value: string) => void;
  isLoading: boolean;
}

const ELEMENT_NORMAL_WIDTH = 160;
const ELEMENT_INPUT_WIDTH = 200;
const ELEMENT_BOOLEAN_WIDTH = 80;

// TODO: Delete this when all apps are higher than v4.2.10
const DEPRECATED_FEATURES = ['heatStrainAlgorithm', 'manDownAlgorithm', 'stressAlgorithm'];

const AppConfigFormItem = memo(({ feature, toggleFeature, setPropertyValue, isLoading }: Props) => {
  const properties = 'properties' in feature ? feature.properties : {};

  const propertiesByElementType: Record<string, 'input' | 'select'> = {
    videoStreamType: 'select',
    locationSource: 'select',
    heatStrainAlgorithm: 'select',
    manDownAlgorithm: 'select',
    stressAlgorithm: 'select',
    showDataInProfile: 'select',
    missionFeedbackUrl: 'input',
  };

  return (
    <ItemWrapperDiv>
      <div>
        <Switch
          style={{ marginRight: '8px' }}
          checked={feature.isEnabled}
          onChange={(checked) => toggleFeature({ ...feature, isEnabled: checked })}
          loading={isLoading}
        />
        {DEPRECATED_FEATURES.includes(feature.type) ? (
          <Tooltip title={i18n.t('mobileAppFeatures.deprecatedMessage')}>
            <b style={{ cursor: 'help' }}>
              <s>{i18n.t(`mobileAppFeatures.config.${feature.type}`)}</s>
            </b>
          </Tooltip>
        ) : (
          <b>{i18n.t(`mobileAppFeatures.config.${feature.type}`)}</b>
        )}
      </div>
      <ItemPropertiesDiv>
        {Object.entries(properties).map(([key, value]) => {
          const elementType = propertiesByElementType[key];

          let width = ELEMENT_NORMAL_WIDTH;

          const options: {
            value: string;
            label: string;
          }[] = (() => {
            switch (key) {
              case 'videoStreamType':
                return videoStreamTypeOptions;
              case 'locationSource':
                return locationSourceOptions;
              default:
                width = ELEMENT_BOOLEAN_WIDTH;
                return booleanOptions;
            }
          })();

          return (
            <PropertyDiv key={key}>
              <PropertyLabelSpan>{i18n.t(`mobileAppFeatures.config.${key}`)}:</PropertyLabelSpan>
              {elementType ? (
                <>
                  {elementType === 'select' && (
                    <Select
                      value={value as string}
                      style={{ width }}
                      options={options}
                      onChange={(selectedValue) =>
                        setPropertyValue(feature.type, key, selectedValue)
                      }
                      disabled={isLoading}
                      loading={isLoading}
                      size="small"
                    />
                  )}
                  {elementType === 'input' && (
                    <Input
                      value={value as string}
                      style={{ width: ELEMENT_INPUT_WIDTH }}
                      placeholder={i18n.t<string>(`mobileAppFeatures.config.${key}`)}
                      onChange={(event) => {
                        setPropertyValue(feature.type, key, event.target.value);
                      }}
                      disabled={isLoading}
                      size="small"
                    />
                  )}
                </>
              ) : (
                <span>{value as string}</span>
              )}
            </PropertyDiv>
          );
        })}
      </ItemPropertiesDiv>
    </ItemWrapperDiv>
  );
});

AppConfigFormItem.displayName = 'AppConfigFormItem';

export default AppConfigFormItem;
