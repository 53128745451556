// Sensor name
// ======================================================

export enum SENSOR_NAME_QUERY {
  connected = 'connected',
  brain_stop = 'brain_stop',
  versions = 'versions',
  battery = 'battery',
  lte_signal_strength = 'lte_signal_strength',
  gps = 'gps',
  body_multi_sensor_v1 = 'body_multi_sensor_v1',
  heart_rate = 'heart_rate',
  gas = 'gas',
  radiation = 'radiation',
  stress = 'stress',
  oxygen_supply = 'oxygen_supply',
  activity_level = 'activity_level',
}

export enum SENSOR_NAME_VARIABLE {
  connected = 'connected',
  connected_history = 'connected_history', // Just connected but with the last 1000 from today
  brainStop = 'brainStop',
  versions = 'versions',
  battery = 'battery',
  lteSignalStrength = 'lteSignalStrength',
  gps = 'gps',
  bodyMultiSensorV1 = 'bodyMultiSensorV1',
  heartRate = 'heartRate',
  gas = 'gas',
  radiation = 'radiation',
  stress = 'stress',
  oxygenSupply = 'oxygenSupply',
  activityLevel = 'activityLevel',
}

// Sensor status
// ======================================================

export enum SENSOR_STATUS_QUERY {
  connected_status = 'connected#status',
  battery_status = 'battery#status',
  lte_signal_strength_status = 'lte_signal_strength#status',
  gps_status = 'gps#status',
  body_multi_sensor_v1_status = 'body_multi_sensor_v1#status',
  heart_rate_status = 'heart_rate#status',
  gas_status = 'gas#status',
  radiation_status = 'radiation#status',
  stress_status = 'stress#status',
  oxygen_supply_status = 'oxygen_supply#status',
  activity_level_status = 'activity_level#status',
  emergency_status = 'emergency#status',
  traak_front_status = 'traak_front#status',
  traak_back_status = 'traak_back#status',
}

export enum SENSOR_STATUS_VARIABLE {
  connectedStatus = 'connectedStatus',
  batteryStatus = 'batteryStatus',
  lteSignalStrengthStatus = 'lteSignalStrengthStatus',
  gpsStatus = 'gpsStatus',
  bodyMultiSensorV1Status = 'bodyMultiSensorV1Status',
  heartRateStatus = 'heartRateStatus',
  gasStatus = 'gasStatus',
  radiationStatus = 'radiationStatus',
  stressStatus = 'stressStatus',
  oxygenSupplyStatus = 'oxygenSupplyStatus',
  activityLevelStatus = 'activityLevelStatus',
  emergencyStatus = 'emergencyStatus',
  traakFrontStatus = 'traakFrontStatus',
  traakBackStatus = 'traakBackStatus',
}

// Generic sensor type
// ======================================================

interface GenericSensorType {
  value: unknown;
  timestamp: string;
}

// GPS sensor type
// ======================================================

export interface GpsCoordinates {
  lat: number;
  lng: number;
  sp?: number;
  br?: number;
  acc?: number;
  filt_sp?: number;
}

export interface GpsSensorType extends GenericSensorType {
  value: GpsCoordinates;
}

// Physiological temperature sensor type
// ======================================================

export interface PhysiologicalTemperatureSensorType extends GenericSensorType {
  value: {
    body_temp: number;
    skin_temp: number;
    data_qual: number;
  };
}

// Heart rate sensor type
// ======================================================

export interface HeartRateSensorType extends GenericSensorType {
  value: number;
}

// Activity level sensor type
// ======================================================

export interface ActivityLevelSensorType extends GenericSensorType {
  // Activity level 0 - Resting
  // Activity level 1 - Walking slow
  // Activity level 2 - Walking fast
  // Activity level 3 - Running
  value: number;
}

// Brain stop sensor type
// ======================================================

export interface BrainStopSensorType extends GenericSensorType {
  value: {
    power_off: boolean;
    /** @deprecated Use power_off instead, but brains v3 still use stop! */
    stop: boolean;
  };
}

// Battery sensor type
// ======================================================

export interface BatterySensorType extends GenericSensorType {
  value: {
    battery_level: number; // From 0 to 100 (if battery_health is 50, a battery_level of 100 means 50% battery level)
    battery_health: number; // From 0 to 100
    voltage: number; // Voltage in volts
    is_charging: number; // 0: not charging, 1: charging
    // TODO: These ones are present sometimes, not sure if relevant
    is_powered?: boolean;
    temperature?: number;
    current: number;
    pg: number;
  };
}

// LTE signal strength sensor type
// ======================================================

enum CellularNetworkRegistrationStatus {
  NO_REGISTERED_SEARCHING = 0, // No registered searching network
  REGISTERED_HOME = 1, // Registered home network
  NOT_REGISTERED_SEARCHING = 2, // Not registered searching network
  REGISTRATION_DENIED = 3, // Registration denied network
  UNKNOWN = 4, // Unknown network
  ROAMING_REGISTERED = 5, // Roaming registered network
  HOME_SMS_ONLY_REGISTERED = 6, // Home SMS only registered network
  SMS_ONLY_ROAMING_REGISTERED = 7, // SMS only roaming registered network
  ATTACHED_EMERG_SERVICES_ONLY = 8, // Attached emergency service only network
}

export interface LteSignalStrengthSensorType extends GenericSensorType {
  value: {
    rsrp: number; // LTE Reference Signal Received Power (RSRP) in dBm
    rsrq: number; // LTE Reference Signal Received Quality (RSRQ) in dB
    bars: number; // A number between 0 to 5 (both inclusive) indicating signal strength
    reg: number | CellularNetworkRegistrationStatus; // PS (Packet Switched) registration status (registered/searching/roaming etc)
  };
}

// Radiation sensor type
// ======================================================

export interface RadiationSensorType extends GenericSensorType {
  value: {
    gamma: number;
    background: number;
  };
}

// Oxygen supply sensor type
// ======================================================

export interface OxygenSupplySensorType extends GenericSensorType {
  value: {
    pressure: number;
    remaining_time: number;
    temp: number;
  };
}

// Gas sensor type
// ======================================================

export enum GAS_SENSOR_TYPE {
  ch4_hc = 'ch4/hc',
  co = 'co',
  co2 = 'co2',
  h2s = 'h2s',
  o2 = 'o2',
}

export interface GasSensorType extends GenericSensorType {
  value: {
    [GAS_SENSOR_TYPE.ch4_hc]: number;
    [GAS_SENSOR_TYPE.co]: number;
    [GAS_SENSOR_TYPE.co2]: number;
    [GAS_SENSOR_TYPE.h2s]: number;
    [GAS_SENSOR_TYPE.o2]: number;
  };
}

// Versions sensor type
// ======================================================

export interface VersionsSensorType extends GenericSensorType {
  value: {
    es_vr: string;
    nrf52_semver: string;
    stm_semver: string;
    algo_lib_semver: string;
    /** @deprecated Use algo_lib_semver instead */
    algo_lib_vr: string;
  };
}
