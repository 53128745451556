// Units

export const HEART_RATE_UNIT = 'BPM';

export const PHYSIOLOGICAL_TEMPERATURE_UNIT = '°C';

export const ACTIVITY_LEVEL_UNIT = '';

export const GAS_CO_UNIT = 'ppm';
export const GAS_CO2_UNIT = 'ppm';
export const GAS_H2S_UNIT = 'ppm';
export const GAS_CH4_HC_UNIT = '%LEL';
export const GAS_O2_UNIT = '%';

export const RADIATION_UNIT = 'μSv/h';

export const AIR_SUPPLY_REMAINING_TIME_UNIT = 'min';
export const AIR_SUPPLY_TANK_PRESSURE_UNIT = 'bar';
export const AIR_SUPPLY_TEMPERATURE_UNIT = '°C';

export const BATTERY_LEVEL_UNIT = '%';
export const BATTERY_HEALTH_UNIT = '%';
export const BATTERY_CHARGING_UNIT = '';
export const BATTERY_VOLTAGE_UNIT = 'V';
export const BATTERY_TEMPERATURE_UNIT = '°C';

export const LTE_NETWORK_CONNECTIVITY_UNIT = 'dBm';
export const LTE_DATA_TRANSMISSION_UNIT = 'dB';
export const LTE_BARS_UNIT = '';
