import CompassOutlined from '@ant-design/icons/CompassOutlined';
import EyeOutlined from '@ant-design/icons/EyeOutlined';
import LockOutlined from '@ant-design/icons/LockOutlined';
import VideoCameraOutlined from '@ant-design/icons/VideoCameraOutlined';
import { Button, Popover, Table, TableColumnsType, Tooltip } from 'antd';
import camelCase from 'lodash/camelCase';
import { Key, memo, ReactNode, useMemo } from 'react';
import styled from 'styled-components';

import Link from '~/components/Link';
import routes from '~/config/routes';
import useAgentsContext from '~/context/useAgentsContext';
import useCurrentUserContext from '~/context/useCurrentUserContext';
import useModalsContext from '~/context/useModalsContext';
import useCompanyFeatures from '~/hooks/useCompanyFeatures';
import useInitialLoading from '~/hooks/useInitialLoading';
import useTableSearch from '~/hooks/useTableSearch';
import i18n from '~/locales/i18n';
import theme from '~/theme';
import { AGENT_STATUS, type Agent, type AgentAlertLevel } from '~/types/agent';
import type { Indicator } from '~/types/indicator';
import type { VersionsSensorType } from '~/types/sensor';
import getAgentStatusColor from '~/utils/agent/getAgentStatusColor';
import getAgentStatusTranslation from '~/utils/agent/getAgentStatusTranslation';
import sortAgentsByStatusRiskLevelAndName from '~/utils/agent/sortAgentsByStatusRiskLevelAndName';
import { computeMissionTime } from '~/utils/dateTime';
import getEquipmentStatusLabel from '~/utils/equipment/getEquipmentStatusLabel';
import getIndicatorLevelColor from '~/utils/indicators/getIndicatorLevelColor';
import getRiskLevelLetterAndColor from '~/utils/indicators/getRiskLevelLetterAndColor';
import curateUrl from '~/utils/parse/curateUrl';

import computeEquipmentStatuses from './utils/computeEquipmentStatuses';

interface TableDataType {
  key: Key;
  id: string;
  agent: Agent;
  completeName: string;
  status: AGENT_STATUS;
  alertLevel: AgentAlertLevel;
  isOffline: boolean;
  isConnectionLost: boolean;
  acronym: string;
  first_name: string;
  last_name: string;
  phone_number: string;
  team: string;
  vehicle: string;
  deviceName: string;
  equipmentStatus: string;
  equipmentStatusColor: string;
  missionStart: string;
  missionEnd: string;
  versions: VersionsSensorType['value'] | undefined;
  riskLevel: number | null;
  indicators: Indicator[];
  actions: ReactNode;
}

const StyledTable = styled(Table<TableDataType>)`
  overflow-x: auto;
  border: none;
  margin-bottom: 16px;

  td {
    background: #ffffff;
  }
`;

const ActionDiv = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 8px;
  align-items: center;
  justify-content: start;
`;

const PopoverSummaryDl = styled.dl`
  margin: 0;

  & > div {
    display: flex;
    gap: 4px;

    dt {
      margin: 0;
      font-weight: bold;
    }

    dd {
      margin: 0;
    }
  }
`;

const CIRCLE_WIDTH = '38px';

const ProfileCircleDiv = styled.div`
  align-items: center;
  display: flex;
  font-weight: regular;
  justify-content: center;
  border-radius: 32px;
  font-size: 12px;
  height: ${CIRCLE_WIDTH};
  width: ${CIRCLE_WIDTH};
  margin: 0;
`;

const RiskLevelLetterDiv = styled.div`
  font-size: 1.1rem;
  font-weight: 600;
  color: ${theme.colors.white};
  padding: 0.5rem 1rem;
  border-radius: 8px;
  display: inline;
  cursor: help;
`;

const AgentsTable = memo(() => {
  const { isInitialLoading } = useInitialLoading();
  const { agents } = useAgentsContext();
  const { openModal } = useModalsContext();
  const { companyFeatures, showLocationHistoryFeature, showIndicatorsFeature } =
    useCompanyFeatures();
  const { isSuperAdmin } = useCurrentUserContext();

  const { getColumnSearchProps } = useTableSearch<TableDataType>();

  const columns: TableColumnsType<TableDataType> = useMemo(
    () => [
      {
        dataIndex: 'completeName',
        title: i18n.t('common.agent'),
        sorter: (a, b) => a.completeName.toLowerCase().localeCompare(b.completeName.toLowerCase()),
        ...getColumnSearchProps({
          dataIndex: 'completeName',
          title: i18n.t('common.agent'),
          renderWithHighlight: ({ text, record, highlightedNode }) => {
            const agentStatusColor = getAgentStatusColor(record);
            const profileCircleDiv = (
              <ProfileCircleDiv
                style={{
                  backgroundColor: agentStatusColor,
                  borderColor: agentStatusColor,
                  color: theme.colors.white,
                  whiteSpace: 'pre',
                }}
                data-id="details-popup-bullet-label"
              >
                {record?.acronym}
              </ProfileCircleDiv>
            );

            return text ? (
              <div
                data-id={`agent-name-${record.agent.email || record.agent.id}`}
                style={{
                  display: 'inline-flex',
                  alignItems: 'center',
                  gap: '8px',
                }}
              >
                {!record.isOffline || !!record.alertLevel ? (
                  <Link to={routes.status({ id: record.agent.id })}>
                    <Tooltip title={i18n.t('agentsPage.viewDetails')}>{profileCircleDiv}</Tooltip>
                  </Link>
                ) : (
                  profileCircleDiv
                )}
                <Popover
                  placement="top"
                  content={
                    <PopoverSummaryDl data-id="agent-attributes">
                      {(['first_name', 'last_name', 'team', 'phone_number'] as const).map(
                        (attributeName) => (
                          <div key={attributeName}>
                            <dt>{i18n.t(`agentForm.${camelCase(attributeName)}`)}:</dt>
                            <dd style={{ opacity: record[attributeName] ? 1 : 0.25 }}>
                              {record[attributeName] || 'n/a'}
                            </dd>
                          </div>
                        ),
                      )}
                    </PopoverSummaryDl>
                  }
                >
                  <span style={{ cursor: 'help', whiteSpace: 'pre' }}>
                    <b>{highlightedNode}</b>
                  </span>
                </Popover>
              </div>
            ) : (
              '-'
            );
          },
        }),
      },
      {
        dataIndex: 'team',
        title: i18n.t('common.team'),
        sorter: (a, b) => a.team.toLowerCase().localeCompare(b.team.toLowerCase()),
        ...getColumnSearchProps({
          dataIndex: 'team',
          title: i18n.t('common.team'),
          renderWithHighlight: ({ text, highlightedNode }) => (
            <span style={{ whiteSpace: 'pre' }}>{text ? highlightedNode : '-'}</span>
          ),
        }),
      },
      {
        dataIndex: 'status',
        title: i18n.t('common.agentStatus'),
        defaultSortOrder: 'ascend',
        sorter: (a: TableDataType, b: TableDataType) => {
          const sorted = sortAgentsByStatusRiskLevelAndName([a.agent, b.agent]);
          return sorted.indexOf(a.agent) - sorted.indexOf(b.agent);
        },
        render: (text, record) => {
          const agentStatusColor = getAgentStatusColor(record);
          return text ? (
            <div
              style={{
                display: 'inline-block',
                borderRadius: '3px',
                color:
                  record.isOffline && record.status !== AGENT_STATUS.alert
                    ? theme.colors.darkGrey
                    : theme.colors.white,
                fontSize: '15px',
                backgroundColor: agentStatusColor,
                padding: '5px 10px',
                whiteSpace: 'pre',
              }}
            >
              {getAgentStatusTranslation({
                isOffline: record.isOffline,
                status: record.status,
              })}
            </div>
          ) : (
            '-'
          );
        },
      },
      {
        dataIndex: 'deviceName',
        title: i18n.t('common.wearinBrain'),
        sorter: (a, b) => a.deviceName.toLowerCase().localeCompare(b.deviceName.toLowerCase()),
        ...getColumnSearchProps({
          dataIndex: 'deviceName',
          title: i18n.t('common.wearinBrain'),
          renderWithHighlight: ({ text, highlightedNode, record }) => {
            const NA = <span style={{ opacity: 0.25 }}>n/a</span>;

            const items = [
              {
                label: 'brainBatteryLteSignal.id',
                value: text || NA,
              },
              {
                label: 'brainBatteryLteSignal.systemVersion',
                value: record?.versions?.stm_semver || NA,
              },
              {
                label: 'brainBatteryLteSignal.bluetoothVersion',
                value: record?.versions?.nrf52_semver || NA,
              },
              {
                label: 'brainBatteryLteSignal.algorithmsVersion',
                value: record?.versions?.algo_lib_semver || record?.versions?.algo_lib_vr || NA,
              },
            ];

            return text ? (
              <Popover
                placement="top"
                content={
                  <PopoverSummaryDl>
                    {items.map((item) => (
                      <div key={item.label}>
                        <dt>{i18n.t(item.label)}:</dt>
                        <dd>{item.value}</dd>
                      </div>
                    ))}
                  </PopoverSummaryDl>
                }
              >
                <div
                  style={{
                    display: 'inline-flex',
                    alignItems: 'center',
                    gap: 8,
                    cursor: 'help',
                    whiteSpace: 'pre',
                  }}
                >
                  <img
                    src={curateUrl('/icons/brain.svg')}
                    alt="Brain"
                    style={{
                      color: theme.colors.darkBlue,
                      height: 24,
                    }}
                  />
                  {highlightedNode}
                </div>
              </Popover>
            ) : (
              '-'
            );
          },
        }),
      },
      {
        dataIndex: 'equipmentStatus',
        title: i18n.t('agentsPage.equipmentStatus'),
        sorter: (a, b) =>
          a.equipmentStatus.toLowerCase().localeCompare(b.equipmentStatus.toLowerCase()),
        render: (text: string, record: TableDataType) => {
          const equipmentStatuses = computeEquipmentStatuses({
            agent: record.agent,
            isOffline: record.isOffline,
            isConnectionLost: record.isConnectionLost,
            companyFeatures,
          });
          const equipmentStatusPopoverItems: {
            title: string;
            color: string;
            label: string;
            description: string;
          }[] = [
            {
              title: i18n.t('common.wearinBrain'),
              color: equipmentStatuses.brain.color.popover,
              label: equipmentStatuses.brain.label,
              description: '',
            },
            ...(companyFeatures.emergencyButton
              ? [
                  {
                    title: i18n.t('carrierDetailsPopup.equipment.sosExternalButton'),
                    color: equipmentStatuses.emergencyButton.color.popover,
                    label: equipmentStatuses.emergencyButton.label,
                    description: getEquipmentStatusLabel(
                      record.agent.equipmentStatus.emergencyButton,
                    ),
                  },
                ]
              : []),
            ...(companyFeatures.heartRateSensor
              ? [
                  {
                    title: i18n.t('carrierDetailsPopup.equipment.heartRate'),
                    color: equipmentStatuses.heartRate.color.popover,
                    label: equipmentStatuses.heartRate.label,
                    description: getEquipmentStatusLabel(record.agent.equipmentStatus.heartRate),
                  },
                ]
              : []),
            ...(companyFeatures.physiologicalTemperatureSensor
              ? [
                  {
                    title: i18n.t('carrierDetailsPopup.equipment.physiologicalTemperature'),
                    color: equipmentStatuses.physiologicalTemperature.color.popover,
                    label: equipmentStatuses.physiologicalTemperature.label,
                    description: getEquipmentStatusLabel(
                      record.agent.equipmentStatus.physiologicalTemperature,
                    ),
                  },
                ]
              : []),
            ...(companyFeatures.activityLevelSensor
              ? [
                  {
                    title: i18n.t('carrierDetailsPopup.equipment.activityLevel'),
                    color: equipmentStatuses.activityLevel.color.popover,
                    label: equipmentStatuses.activityLevel.label,
                    description: getEquipmentStatusLabel(
                      record.agent.equipmentStatus.activityLevel,
                    ),
                  },
                ]
              : []),
            ...(companyFeatures.gasSensor
              ? [
                  {
                    title: i18n.t('carrierDetailsPopup.equipment.gas'),
                    color: equipmentStatuses.gas.color.popover,
                    label: equipmentStatuses.gas.label,
                    description: getEquipmentStatusLabel(record.agent.equipmentStatus.gas),
                  },
                ]
              : []),
            ...(companyFeatures.radiationSensor
              ? [
                  {
                    title: i18n.t('carrierDetailsPopup.equipment.radiation'),
                    color: equipmentStatuses.radiation.color.popover,
                    label: equipmentStatuses.radiation.label,
                    description: getEquipmentStatusLabel(record.agent.equipmentStatus.radiation),
                  },
                ]
              : []),
            ...(companyFeatures.oxygenSupplySensor
              ? [
                  {
                    title: i18n.t('carrierDetailsPopup.equipment.oxygenSupply'),
                    color: equipmentStatuses.oxygenSupply.color.popover,
                    label: equipmentStatuses.oxygenSupply.label,
                    description: getEquipmentStatusLabel(record.agent.equipmentStatus.oxygenSupply),
                  },
                ]
              : []),
            ...(companyFeatures.impactDetectionFront
              ? [
                  {
                    title: i18n.t('carrierDetailsPopup.equipment.traakFront'),
                    color: equipmentStatuses.traakFront.color.popover,
                    label: equipmentStatuses.traakFront.label,
                    description: getEquipmentStatusLabel(record.agent.equipmentStatus.traakFront),
                  },
                ]
              : []),
            ...(companyFeatures.impactDetectionBack
              ? [
                  {
                    title: i18n.t('carrierDetailsPopup.equipment.traakBack'),
                    color: equipmentStatuses.traakBack.color.popover,
                    label: equipmentStatuses.traakBack.label,
                    description: getEquipmentStatusLabel(record.agent.equipmentStatus.traakBack),
                  },
                ]
              : []),
          ];
          const showPopover = !record?.agent?.isOffline;
          return text ? (
            <Popover
              placement="top"
              content={
                showPopover ? (
                  <PopoverSummaryDl data-id="agent-equipment-details">
                    {equipmentStatusPopoverItems.map((item) => (
                      <div key={item.title}>
                        <dt>{item.title}:</dt>
                        <dd data-id={item.title} style={{ color: item.color }}>
                          {item.description ? item.description : item.label}
                        </dd>
                      </div>
                    ))}
                  </PopoverSummaryDl>
                ) : null
              }
            >
              <div
                data-id={`agent-equipment-status-${record.agent.email || record.agent.id}`}
                style={{
                  display: 'inline-block',
                  cursor: showPopover ? 'help' : 'default',
                  color: equipmentStatuses.status.color.table,
                  whiteSpace: 'pre',
                }}
              >
                {text}
              </div>
            </Popover>
          ) : (
            '-'
          );
        },
      },
      ...(showIndicatorsFeature
        ? [
            {
              dataIndex: 'riskLevel',
              title: i18n.t('indicators.riskLevel'),
              sorter: (a: TableDataType, b: TableDataType) =>
                (a.riskLevel || -1) - (b.riskLevel || -1),
              render: (text: number, record: TableDataType) => {
                const { scoreLetter, scoreColor } = getRiskLevelLetterAndColor(
                  record.riskLevel || 0,
                );
                return record?.riskLevel && (!record.isOffline || !!record.alertLevel) ? (
                  <Popover
                    placement="top"
                    content={
                      <PopoverSummaryDl>
                        <div key={JSON.stringify(record.indicators)}>
                          <dt>{i18n.t('indicators.riskLevel')}:</dt>
                          <dd style={{ color: scoreColor }}>
                            {scoreLetter} ({record.riskLevel || 0}/10)
                          </dd>
                        </div>
                        {record.indicators.map((indicator) => (
                          <div key={JSON.stringify(indicator)}>
                            <dt>{indicator.name}:</dt>
                            <dd style={{ color: getIndicatorLevelColor(indicator.value) }}>
                              {indicator.textualValue || indicator.value}
                            </dd>
                          </div>
                        ))}
                      </PopoverSummaryDl>
                    }
                  >
                    <RiskLevelLetterDiv style={{ backgroundColor: scoreColor }}>
                      {scoreLetter}
                    </RiskLevelLetterDiv>
                  </Popover>
                ) : (
                  '-'
                );
              },
            },
          ]
        : []),
      {
        dataIndex: 'missionStart',
        title: i18n.t('agentsPage.missionStart'),
        sorter: (a, b) => a.missionStart.toLowerCase().localeCompare(b.missionStart.toLowerCase()),
        render: (text, record) => record?.missionStart || '-',
      },
      {
        dataIndex: 'missionEnd',
        title: i18n.t('agentsPage.missionEnd'),
        sorter: (a, b) => a.missionEnd.toLowerCase().localeCompare(b.missionEnd.toLowerCase()),
        render: (text, record) => record?.missionEnd || '-',
      },
      {
        dataIndex: 'vehicle',
        title: i18n.t('common.vehicle'),
        sorter: (a: TableDataType, b: TableDataType) =>
          a.vehicle.toLowerCase().localeCompare(b.vehicle.toLowerCase()),
        ...getColumnSearchProps({
          dataIndex: 'vehicle',
          title: i18n.t('common.vehicle'),
          renderWithHighlight: ({ text, highlightedNode }) => (
            <span style={{ whiteSpace: 'pre' }}>{text ? highlightedNode : '-'}</span>
          ),
        }),
      },
      {
        dataIndex: 'actions',
        title: i18n.t('common.actions'),
        fixed: 'right',
        render: (text, record) => {
          const isAvailable =
            !record?.agent?.isOffline || record?.agent?.status === AGENT_STATUS.alert;

          return (
            <ActionDiv>
              <Tooltip
                title={
                  isAvailable
                    ? i18n.t('agentsPage.viewDetails')
                    : i18n.t('agentsPage.cannotViewOfflineAgentDetails')
                }
              >
                <div>
                  <Link
                    to={routes.status({ id: record?.agent?.id })}
                    style={{ display: 'inline-block' }}
                  >
                    <Button
                      disabled={!isAvailable}
                      data-id="agent-details-btn"
                      data-agent-id={record?.agent?.id}
                      data-agent-email={record?.agent?.email}
                      icon={<EyeOutlined />}
                    />
                  </Link>
                </div>
              </Tooltip>
              {showLocationHistoryFeature && (
                <Tooltip title={i18n.t('agentsPage.locationHistory')}>
                  <Button
                    onClick={() => {
                      if (record?.agent?.id) {
                        openModal({
                          type: 'locationHistory',
                          agentId: record?.agent?.id,
                        });
                      }
                    }}
                    data-id="location-history-btn"
                    icon={<CompassOutlined />}
                  />
                </Tooltip>
              )}
              {companyFeatures.playbackRecordedVideos && (
                <Tooltip title={i18n.t('agentsPage.videoRecordings')}>
                  <Button
                    onClick={() => {
                      if (record?.agent?.id) {
                        openModal({
                          type: 'videoRecordings',
                          defaultAgentId: record?.agent?.id,
                          initialDateRange: ['', ''],
                        });
                      }
                    }}
                    data-id="video-recordings-btn"
                    icon={<VideoCameraOutlined />}
                  />
                </Tooltip>
              )}
              {companyFeatures.agentDebugPanel && isSuperAdmin && (
                <Tooltip title={i18n.t('agentDebugModal.buttonTooltip')} placement="top">
                  <Button
                    data-id={`agent-debug-panel-btn-${record.agent.email || record.agent.id}`}
                    type="dashed"
                    icon={<LockOutlined />}
                    onClick={() => {
                      if (record?.id) {
                        openModal({
                          type: 'agentDebug',
                          agentId: record.id,
                        });
                      }
                    }}
                  />
                </Tooltip>
              )}
            </ActionDiv>
          );
        },
      },
    ],
    [
      companyFeatures,
      getColumnSearchProps,
      isSuperAdmin,
      openModal,
      showIndicatorsFeature,
      showLocationHistoryFeature,
    ],
  );

  const data: TableDataType[] = useMemo(
    () =>
      agents.map((agent) => {
        const equipmentStatuses = computeEquipmentStatuses({
          agent,
          isOffline: agent.isOffline,
          isConnectionLost: agent.isConnectionLost,
          companyFeatures,
        });
        return {
          key: agent.email || agent.id,
          id: agent.id,
          agent,
          completeName: agent.completeName,
          status: agent.status,
          alertLevel: agent.alertLevel,
          isOffline: agent.isOffline,
          isConnectionLost: agent.isConnectionLost,
          acronym: agent.attributes.acronym || '',
          first_name: agent.attributes.first_name || '',
          last_name: agent.attributes.last_name || '',
          phone_number: agent.attributes.phone_number || '',
          team: agent.attributes.team || '',
          vehicle: agent.attributes.plate_number || '',
          deviceName: agent.deviceName || '',
          equipmentStatus: equipmentStatuses.status.label || '',
          equipmentStatusColor: equipmentStatuses.status.color.table,
          riskLevel: agent.riskLevel,
          indicators: agent.indicators,
          missionStart: computeMissionTime(agent.missionStartTimeISO),
          missionEnd: computeMissionTime(agent.missionEndTimeISO),
          versions: agent.sensors?.versions?.value,
          actions: '',
        };
      }),
    [agents, companyFeatures],
  );

  return (
    <StyledTable
      data-id="agents-table"
      tableLayout="auto"
      loading={isInitialLoading}
      columns={columns}
      dataSource={data}
      sortDirections={['ascend', 'descend', 'ascend']}
      pagination={{
        defaultPageSize: 5000,
        showSizeChanger: false,
        pageSizeOptions: undefined,
        hideOnSinglePage: true,
        position: [],
      }}
    />
  );
});

AgentsTable.displayName = 'AgentsTable';

export default AgentsTable;
