import { useCallback, useMemo } from 'react';

import useMutationSetCompanyAttribute from '~/apollo/hooks/company/useMutationSetCompanyAttribute';
import useCompany from '~/hooks/useCompany';

const MOBILE_APP_FEATURES_ATTRIBUTE_NAME = 'configuration';

export type AppConfigVideoStreamType = 'IVS_LOW_LATENCY' | 'IVS_REALTIME' | 'KVS';
export type AppConfigLocationSource = 'PlayServices' | 'LocationManager';
export type AppConfigBoolean = 'true' | 'false';

export type MobileAppFeature =
  | {
      type: 'video';
      isEnabled: boolean;
      properties: {
        videoStreamType: AppConfigVideoStreamType;
      };
    }
  | {
      type: 'geoLocation';
      isEnabled: boolean;
      properties: {
        locationSource: AppConfigLocationSource;
      };
    }
  | {
      type: 'map';
      isEnabled: boolean;
    }
  | {
      type: 'profile';
      isEnabled: boolean;
    }
  | {
      type: 'userData';
      isEnabled: boolean;
      properties: {
        missionFeedbackUrl: string;
        heatStrainAlgorithm: AppConfigBoolean;
        manDownAlgorithm: AppConfigBoolean;
        stressAlgorithm: AppConfigBoolean;
      };
    }
  // TODO: Delete this when all apps are higher than v4.2.10
  // deprecated start
  | {
      type: 'heatStrainAlgorithm';
      isEnabled: boolean;
      properties: {
        showDataInProfile: AppConfigBoolean;
      };
    }
  | {
      type: 'manDownAlgorithm';
      isEnabled: boolean;
      properties: {
        showDataInProfile: AppConfigBoolean;
      };
    }
  | {
      type: 'stressAlgorithm';
      isEnabled: boolean;
      properties: {
        showDataInProfile: AppConfigBoolean;
      };
    }
  // deprecated end
  | {
      type: 'synergise';
      isEnabled: boolean;
    }
  | {
      type: 'nrfDfu';
      isEnabled: boolean;
    }
  | {
      type: 'multiSensorPairing';
      isEnabled: boolean;
    };

export interface MobileAppFeatureSchema {
  schemaVersion: `${number}.${number}.${number}`;
  features: MobileAppFeature[];
}

export const DEFAULT_APP_FEATURES_SCHEMA: MobileAppFeatureSchema = {
  schemaVersion: '0.0.1',
  features: [
    {
      type: 'video',
      isEnabled: true,
      properties: {
        videoStreamType: 'IVS_REALTIME',
      },
    },
    {
      type: 'geoLocation',
      isEnabled: true,
      properties: {
        locationSource: 'PlayServices',
      },
    },
    {
      type: 'map',
      isEnabled: true,
    },
    {
      type: 'profile',
      isEnabled: true,
    },
    {
      type: 'userData',
      isEnabled: true,
      properties: {
        missionFeedbackUrl: '<FEEDBACK_URL>',
        heatStrainAlgorithm: 'true',
        manDownAlgorithm: 'true',
        stressAlgorithm: 'true',
      },
    },
    // TODO: Delete this when all apps are higher than v4.2.10
    // deprecated start
    {
      type: 'heatStrainAlgorithm',
      isEnabled: true,
      properties: {
        showDataInProfile: 'true',
      },
    },
    {
      type: 'manDownAlgorithm',
      isEnabled: true,
      properties: {
        showDataInProfile: 'true',
      },
    },
    {
      type: 'stressAlgorithm',
      isEnabled: true,
      properties: {
        showDataInProfile: 'true',
      },
    },
    // deprecated end
    {
      type: 'synergise',
      isEnabled: true,
    },
    {
      type: 'nrfDfu',
      isEnabled: true,
    },
    {
      type: 'multiSensorPairing',
      isEnabled: true,
    },
  ],
};

export default function useCompanyMobileAppFeatures(): {
  configurationValue: string;
  mobileAppFeatures: MobileAppFeatureSchema;
  areMobileAppFeaturesLoaded: boolean;
  setMobileAppFeatures: (schema: MobileAppFeatureSchema) => Promise<void>;
  isSetMobileAppFeaturesLoading: boolean;
} {
  const { currentCompany } = useCompany();
  const { setCompanyAttribute, isLoading: isSetMobileAppFeaturesLoading } =
    useMutationSetCompanyAttribute();

  const value = useMemo(
    () =>
      currentCompany?.attributes?.find(
        (attribute) => attribute?.name === MOBILE_APP_FEATURES_ATTRIBUTE_NAME,
      )?.value,
    [currentCompany?.attributes],
  );

  const { mobileAppFeatures, configurationValue } = useMemo(() => {
    let didError = false;
    let configuration = DEFAULT_APP_FEATURES_SCHEMA;
    try {
      if (value) {
        const parsedValue = JSON.parse(value);
        configuration = {
          ...DEFAULT_APP_FEATURES_SCHEMA,
          ...parsedValue,
          features: [...parsedValue.features, ...DEFAULT_APP_FEATURES_SCHEMA.features].filter(
            (feature, index, self) => index === self.findIndex((f) => f.type === feature.type),
          ),
        };
      }
    } catch (error) {
      didError = true;
    }
    return {
      mobileAppFeatures: configuration,
      configurationValue: didError ? value : JSON.stringify(configuration, null, 2),
    };
  }, [value]);

  const areMobileAppFeaturesLoaded = useMemo(() => !!currentCompany, [currentCompany]);

  const setMobileAppFeatures = useCallback(
    async (schema: MobileAppFeatureSchema) => {
      await setCompanyAttribute({
        name: MOBILE_APP_FEATURES_ATTRIBUTE_NAME,
        value: JSON.stringify(schema),
      });
    },
    [setCompanyAttribute],
  );

  return useMemo(
    () => ({
      configurationValue: configurationValue || '',
      mobileAppFeatures,
      areMobileAppFeaturesLoaded,
      setMobileAppFeatures,
      isSetMobileAppFeaturesLoading,
    }),
    [
      configurationValue,
      mobileAppFeatures,
      areMobileAppFeaturesLoaded,
      setMobileAppFeatures,
      isSetMobileAppFeaturesLoading,
    ],
  );
}
