import { Progress } from 'antd';
import { memo, useMemo } from 'react';
import styled from 'styled-components';

import theme from '~/theme';
import type { Indicator } from '~/types/indicator';
import getIndicatorLevelColor from '~/utils/indicators/getIndicatorLevelColor';

export const CardDiv = styled.div`
  background: ${theme.colors.greyBackground};
  padding: 1rem;
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.05);
`;

export const H3 = styled.h3`
  margin: 0;
`;

const FlexDiv = styled.div`
  display: grid;
  grid-template-columns: minmax(0, auto) minmax(0, 1fr);
  align-items: center;
  gap: 0.75rem;
`;

const StyledProgress = styled(Progress)<{ $multiplier: number }>`
  .ant-progress-steps-item {
    width: calc(${(props) => props.$multiplier} * 14px) !important;
  }
`;

interface Props {
  indicator: Indicator;
}

const MetricCard = memo(({ indicator }: Props) => {
  const percent = indicator.value * 10;

  const curatedPercent = useMemo(() => {
    if (!percent || Number.isNaN(percent) || percent < 10) {
      return 10;
    }
    if (percent > 90) {
      return 90;
    }
    return percent;
  }, [percent]);

  const strokeColor = useMemo(() => getIndicatorLevelColor(indicator.value), [indicator.value]);

  const multiplier = useMemo(() => {
    if (indicator.steps === 2) {
      return 5.572;
    }
    return 1;
  }, [indicator.steps]);

  const minimumPercent = useMemo(() => {
    if (indicator.steps === 2) {
      return 30;
    }
    return 10;
  }, [indicator.steps]);

  return (
    <CardDiv>
      <H3>{indicator.name}</H3>
      <FlexDiv>
        <StyledProgress
          $multiplier={multiplier}
          percent={curatedPercent < minimumPercent ? minimumPercent : curatedPercent}
          steps={indicator.steps}
          strokeColor={strokeColor}
          style={{
            width: '100%',
          }}
          showInfo={false}
        />
        <div>{indicator.textualValue}</div>
      </FlexDiv>
    </CardDiv>
  );
});

MetricCard.displayName = 'MetricCard';

export default MetricCard;
