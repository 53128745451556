import { memo } from 'react';
import ReactDOMServer from 'react-dom/server';

import theme from '~/theme';

interface Props {
  fill: string;
  isOnAlert: boolean;
  isVehicle: boolean;
}

const MapMarker = memo(
  ({ fill = theme.colors.blue, isOnAlert = false, isVehicle = false }: Props) => {
    const agentOrVehicle = isVehicle ? (
      <>
        <path
          d="m 29,18.482 c 0,-6.0751 4.9249,-11 11,-11 6.0751,0 11,4.9249 11,11 0,6.0751 -4.9249,11 -11,11 -6.0751,0 -11,-4.9249 -11,-11 z"
          fill={fill}
        />
        <path
          d="M 48.8327,20.819 H 48.104 c 0.1549,0.3058 0.2353,0.6421 0.2351,0.9829 0,0.04 0,0.0828 0,0.1257 h 0.376 c 0.042,-2e-4 0.0826,-0.015 0.1144,-0.0417 0.0318,-0.0267 0.0527,-0.0636 0.059,-0.104 l 0.1263,-0.7657 c 0.0034,-0.025 0.0012,-0.0504 -0.0064,-0.0745 -0.0077,-0.024 -0.0206,-0.0462 -0.0379,-0.0649 -0.0173,-0.0188 -0.0386,-0.0336 -0.0624,-0.0436 -0.0238,-0.01 -0.0495,-0.0148 -0.0754,-0.0142 z"
          fill="#ffffff"
        />
        <path
          d="m 47.3199,20.7705 c -0.2875,-0.3205 -0.6936,-0.5175 -1.1298,-0.548 -0.4362,-0.0305 -0.8671,0.1079 -1.1988,0.3851 -0.3317,0.2771 -0.5373,0.6705 -0.572,1.0944 -0.0347,0.424 0.1044,0.8441 0.3869,1.1687 0.2824,0.3246 0.6854,0.5275 1.1211,0.5645 0.4357,0.0369 0.8687,-0.0952 1.2047,-0.3674 0.336,-0.2723 0.5476,-0.6626 0.5889,-1.086 0.0412,-0.4234 -0.0913,-0.8454 -0.3687,-1.1742 z m -1.2752,1.7942 c -0.1482,0 -0.293,-0.0427 -0.4162,-0.1227 -0.1232,-0.0801 -0.2193,-0.1939 -0.276,-0.327 -0.0567,-0.1331 -0.0715,-0.2796 -0.0426,-0.421 0.0289,-0.1413 0.1003,-0.2711 0.205,-0.373 0.1048,-0.1019 0.2383,-0.1713 0.3837,-0.1994 0.1453,-0.0281 0.2959,-0.0137 0.4328,0.0415 0.1369,0.0551 0.254,0.1485 0.3363,0.2683 0.0823,0.1198 0.1263,0.2607 0.1263,0.4048 0.0015,0.0966 -0.0167,0.1926 -0.0537,0.2823 -0.0369,0.0897 -0.0919,0.1714 -0.1616,0.2403 -0.0697,0.0688 -0.1528,0.1235 -0.2445,0.1608 -0.0917,0.0374 -0.1901,0.0566 -0.2895,0.0566 z"
          fill="#ffffff"
        />
        <path
          d="m 35.4907,20.2133 c -0.3331,0 -0.6587,0.0961 -0.9357,0.2761 -0.2769,0.1801 -0.4927,0.4359 -0.62,0.7352 -0.1273,0.2993 -0.1605,0.6287 -0.0953,0.9463 0.0652,0.3177 0.2258,0.6094 0.4616,0.8382 0.2357,0.2289 0.536,0.3846 0.8627,0.4474 0.3268,0.0628 0.6654,0.03 0.973,-0.0943 0.3076,-0.1244 0.5703,-0.3347 0.7549,-0.6043 0.1847,-0.2696 0.2829,-0.5864 0.2823,-0.9103 0,-0.2148 -0.0435,-0.4276 -0.1282,-0.6261 -0.0846,-0.1984 -0.2087,-0.3787 -0.3651,-0.5305 -0.1563,-0.1518 -0.3419,-0.2721 -0.5462,-0.3541 -0.2042,-0.082 -0.4231,-0.1239 -0.644,-0.1236 z m 0,2.3629 c -0.1482,0 -0.2931,-0.0427 -0.4163,-0.1228 -0.1232,-0.0801 -0.2192,-0.1938 -0.2759,-0.327 -0.0567,-0.1331 -0.0716,-0.2796 -0.0427,-0.4209 0.029,-0.1414 0.1003,-0.2712 0.2051,-0.3731 0.1048,-0.1019 0.2383,-0.1712 0.3836,-0.1994 0.1453,-0.0281 0.296,-0.0136 0.4329,0.0415 0.1369,0.0551 0.2539,0.1485 0.3362,0.2683 0.0824,0.1199 0.1263,0.2607 0.1263,0.4048 0,0.1932 -0.0789,0.3786 -0.2194,0.5152 -0.1406,0.1366 -0.3311,0.2134 -0.5298,0.2134 z"
          fill="#ffffff"
        />
        <path
          d="m 32.4145,20.819 c -0.0612,-0.0013 -0.122,0.0109 -0.1777,0.0357 -0.0557,0.0247 -0.1049,0.0614 -0.1439,0.1074 -0.039,0.0459 -0.0668,0.0998 -0.0813,0.1577 -0.0146,0.0578 -0.0154,0.1181 -0.0025,0.1764 l 0.1851,0.8 c 0.0167,0.0751 0.0593,0.1424 0.1208,0.1907 0.0614,0.0483 0.138,0.0748 0.2171,0.075 h 0.758 c -0.0504,-0.1865 -0.0761,-0.3786 -0.0764,-0.5714 8e-4,-0.3411 0.0822,-0.6774 0.238,-0.9829 z"
          fill="#ffffff"
        />
        <path
          d="m 43.7615,21.679 h -5.9644 c 0,0.04 0,0.08 0,0.1229 -3e-4,0.1929 -0.026,0.3849 -0.0764,0.5714 h 6.1054 c -0.0504,-0.1865 -0.076,-0.3785 -0.0764,-0.5714 0.0118,-0.0429 0.0118,-0.0829 0.0118,-0.1229 z"
          fill="#ffffff"
        />
        <path
          d="M 48.9063,20.1762 V 20.1219 L 48.2276,17.9762 C 48.206,17.9078 48.164,17.8472 48.107,17.802 48.05,17.7567 47.9806,17.7289 47.9074,17.7219 l -3.2026,-0.2857 -0.5582,-1.76 c -0.0026,-0.0055 -0.0037,-0.0115 -0.0032,-0.0175 6e-4,-0.006 0.0028,-0.0118 0.0063,-0.0167 0.0036,-0.005 0.0085,-0.0089 0.0141,-0.0114 0.0056,-0.0025 0.0119,-0.0036 0.018,-0.003 h 0.2762 c 0.005,0 0.01,-10e-4 0.0146,-0.0028 0.0047,-0.0019 0.0089,-0.0046 0.0124,-0.0081 0.0036,-0.0034 0.0064,-0.0075 0.0083,-0.012 0.0019,-0.0045 0.0029,-0.0094 0.0029,-0.0142 V 14.959 c 0.0021,-0.0044 0.0032,-0.0093 0.0032,-0.0142 0,-0.005 -0.0011,-0.0099 -0.0032,-0.0143 l -0.5524,-0.4457 c -0.0086,-0.0025 -0.0178,-0.0025 -0.0264,0 h -0.4495 c -0.0042,-0.0019 -0.0087,-0.0028 -0.0133,-0.0028 -0.0045,0 -0.009,9e-4 -0.0132,0.0028 l -0.1939,0.1914 c -0.0086,0.0028 -0.0179,0.0028 -0.0264,0 H 32.5968 c -0.0463,0.0014 -0.0919,0.0119 -0.134,0.0307 -0.042,0.0189 -0.0798,0.0458 -0.111,0.0791 -0.0313,0.0333 -0.0553,0.0723 -0.0707,0.1148 -0.0155,0.0425 -0.022,0.0875 -0.0192,0.1325 v 5.3972 c 0,0.0098 0.004,0.0193 0.0111,0.0262 0.0072,0.007 0.0169,0.0109 0.027,0.0109 h 1.3722 c 0.2424,-0.3156 0.5665,-0.5632 0.9393,-0.7176 0.3728,-0.1543 0.7809,-0.2099 1.183,-0.1612 0.402,0.0488 0.7836,0.2002 1.106,0.4389 0.3223,0.2386 0.574,0.556 0.7293,0.9199 h 6.2964 c 0.1554,-0.3626 0.4066,-0.6789 0.7282,-0.9167 0.3216,-0.2379 0.7021,-0.3888 1.103,-0.4375 0.4009,-0.0487 0.8079,0.0065 1.1798,0.16 0.3719,0.1535 0.6954,0.3999 0.9379,0.7142 h 0.8021 c 0.0368,0.0011 0.0734,-0.0063 0.1067,-0.0217 0.0333,-0.0154 0.0624,-0.0383 0.0848,-0.0668 0.0224,-0.0285 0.0375,-0.0617 0.044,-0.097 0.0066,-0.0353 0.0044,-0.0716 -0.0064,-0.1059 z M 41.232,17.7848 v -2.0286 h 2.0566 l 0.6464,2.0286 z"
          fill="#ffffff"
        />
        <path
          d="m 60,52.482 c 0,11.0457 -11,23 -20,27.5 -10.5,-6 -20,-16.4543 -20,-27.5 0,-11.0457 8.9543,-20 20,-20 11.0457,0 20,8.9543 20,20 z"
          fill={fill}
        />
      </>
    ) : (
      <path
        d="m 60,52.482 c 0,11.0457 -11,23 -20,27.5 -10.5,-6 -20,-16.4543 -20,-27.5 0,-11.0457 8.9543,-20 20,-20 11.0457,0 20,8.9543 20,20 z"
        fill={fill}
      />
    );

    return (
      <svg
        width="80"
        height="80"
        viewBox="0 0 80 80"
        fill="none"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs />
        {isOnAlert ? (
          <path
            d="M 73.1579,36.5789 C 73.1579,54.0195 55.7895,72.8947 41.5789,80 25,70.5263 10,54.0195 10,36.5789 10,19.1384 24.1384,5 41.5789,5 c 17.4406,0 31.579,14.1384 31.579,31.5789 z"
            fill={fill}
          />
        ) : (
          agentOrVehicle
        )}
      </svg>
    );
  },
);

export default MapMarker;

export function getMapMarkerDataUrl({ fill, isOnAlert, isVehicle }: Props) {
  const svgString = ReactDOMServer.renderToString(
    <MapMarker fill={fill} isOnAlert={isOnAlert} isVehicle={isVehicle} />,
  );

  return `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svgString)}`;
}
