import { memo, useMemo } from 'react';

import SectionList from '~/components/DetailsPopup/components/SectionList';
import { RADIATION_UNIT } from '~/config/units';
import useModalsContext from '~/context/useModalsContext';
import i18n from '~/locales/i18n';
import type { Agent } from '~/types/agent';
import getEquipmentStatusDysfunctionsColor from '~/utils/equipment/getEquipmentStatusDysfunctionsColor';
import formatNumberWithUnit from '~/utils/parse/formatNumberWithUnit';

const RADIATION_DECIMALS = 3;

interface Props {
  agent: Agent | undefined;
}

const ContentRadiation = memo(({ agent }: Props) => {
  const { openModal } = useModalsContext();

  const { isWorking } = getEquipmentStatusDysfunctionsColor(
    agent?.equipmentStatus?.radiation?.status,
  );

  const gammaValue = formatNumberWithUnit({
    number: isWorking ? agent?.sensors?.radiation?.value?.gamma : undefined,
    unit: RADIATION_UNIT,
    decimals: RADIATION_DECIMALS,
  });

  const backgroundValue = formatNumberWithUnit({
    number: isWorking ? agent?.sensors?.radiation?.value?.background : undefined,
    unit: RADIATION_UNIT,
    decimals: RADIATION_DECIMALS,
  });

  const list = useMemo(
    () => [
      {
        labelText: i18n.t('carrierDetailsPopup.radiation.gamma'),
        valueText: gammaValue,
        style: { opacity: gammaValue === 'n/a' ? 0.4 : 1 },
        onClickLabel: () => {
          openModal({
            type: 'measurementGraph',
            measurementKind: 'radiationGamma',
            title: `${i18n.t('carrierDetailsPopup.equipment.radiation')} (${i18n.t('carrierDetailsPopup.radiation.gamma').toLowerCase()})`,
            agentName: agent?.completeName || '',
            brainId: agent?.deviceName || '',
            carrierId: agent?.id || '',
          });
        },
      },
      {
        labelText: i18n.t('carrierDetailsPopup.radiation.background'),
        valueText: backgroundValue,
        style: { opacity: backgroundValue === 'n/a' ? 0.4 : 1 },
        onClickLabel: () => {
          openModal({
            type: 'measurementGraph',
            measurementKind: 'radiationBackground',
            title: `${i18n.t('carrierDetailsPopup.equipment.radiation')} (${i18n.t('carrierDetailsPopup.radiation.background').toLowerCase()})`,
            agentName: agent?.completeName || '',
            brainId: agent?.deviceName || '',
            carrierId: agent?.id || '',
          });
        },
      },
    ],
    [gammaValue, backgroundValue, agent?.completeName, agent?.deviceName, agent?.id, openModal],
  );

  if (!agent) {
    return null;
  }

  return <SectionList elements={list} />;
});

ContentRadiation.displayName = 'ContentRadiation';

export default ContentRadiation;
