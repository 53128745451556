import { gql, useQuery, type ApolloError } from '@apollo/client';
import { useMemo } from 'react';

import { MEASUREMENT_ITEMS_QUERY_BODY } from '~/apollo/operations/shared';
import { SENSOR_NAME_QUERY, SENSOR_NAME_VARIABLE, type RadiationSensorType } from '~/types/sensor';

import type { MeasurementDataPoint } from './types';

interface RadiationMeasurementHistoryQueryVariables {
  carrierId: string;
}

interface RadiationMeasurementHistoryQueryData {
  carrier: {
    device: {
      __typename?: 'Device';
      radiation: {
        __typename?: 'MeasurementConnection';
        items: RadiationSensorType[];
      };
    };
  };
}

export default function useQueryRadiationMeasurementHistory({
  carrierId,
  skip,
}: {
  carrierId: string;
  skip: boolean;
}): {
  gammaDataPoints: MeasurementDataPoint[];
  backgroundDataPoints: MeasurementDataPoint[];
  isLoading: boolean;
  error?: ApolloError;
  refetch: () => Promise<void>;
} {
  const { loading, error, data, refetch } = useQuery<
    RadiationMeasurementHistoryQueryData,
    RadiationMeasurementHistoryQueryVariables
  >(
    gql`
    query QueryRadiationMeasurementHistory($carrierId: ID!) {
        carrier(id: $carrierId) {
            device {
              ${SENSOR_NAME_VARIABLE.radiation}: measurements(type: "${SENSOR_NAME_QUERY.radiation}", limit: 1000) ${MEASUREMENT_ITEMS_QUERY_BODY}
            }
          }
      }
    
  `,
    {
      variables: { carrierId },
      fetchPolicy: 'no-cache',
      skip,
    },
  );

  const parsedItems = useMemo(
    () =>
      (data?.carrier?.device?.radiation?.items || []).map((items) => ({
        ...items,
        value: JSON.parse(items.value as unknown as string) as RadiationSensorType['value'],
      })),
    [data?.carrier?.device?.radiation?.items],
  );

  return useMemo(
    () => ({
      gammaDataPoints: parsedItems
        .map((item) => ({
          value: parseFloat(`${item.value.gamma}`),
          timestamp: item.timestamp,
        }))
        .filter((dataPoint) => Number.isFinite(dataPoint.value)),
      backgroundDataPoints: parsedItems
        .map((item) => ({
          value: parseFloat(`${item.value.background}`),
          timestamp: item.timestamp,
        }))
        .filter((dataPoint) => Number.isFinite(dataPoint.value)),
      isLoading: loading,
      error,
      refetch: async () => {
        await refetch();
      },
    }),
    [parsedItems, loading, error, refetch],
  );
}
