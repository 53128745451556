import { AGENT_STATUS } from '~/types/agent';
import { MARKER_TYPE } from '~/types/marker';
import { statusColorMap } from '~/utils/agent/getAgentStatusColor';
import getAlarmColorFromLevel from '~/utils/alarm/getAlarmColorFromLevel';

import { getMapMarkerDataUrl } from '../../MapMarker';
import type { MarkerType } from '../hooks/useMarkers';

function getMarkerColor(marker: MarkerType): string {
  if (marker.status === AGENT_STATUS.alert && marker.alertLevel) {
    return getAlarmColorFromLevel(marker.alertLevel).text;
  }
  return statusColorMap[marker.status];
}

function computeScaledSize(isHighlighted?: boolean) {
  const size = isHighlighted ? 100 : 80;

  return new google.maps.Size(size, size);
}

function computeLabelOrigin(status: AGENT_STATUS, isHighlighted?: boolean) {
  const alert = status === AGENT_STATUS.alert;
  let labelOriginX;
  let labelOriginY;

  if (isHighlighted) {
    labelOriginX = 50;
    labelOriginY = alert ? 50 : 68;
  } else {
    labelOriginX = 40;
    labelOriginY = alert ? 40 : 52;
  }

  return new google.maps.Point(labelOriginX, labelOriginY);
}

export default function computeIcon(marker: MarkerType) {
  const scaledSize = computeScaledSize(marker.isHighlighted);
  const labelOrigin = computeLabelOrigin(marker.status, marker.isHighlighted);
  const icon: google.maps.Icon = {
    url: getMapMarkerDataUrl({
      fill: getMarkerColor(marker),
      isOnAlert: !!marker.alertLevel,
      isVehicle: marker.type === MARKER_TYPE.vehicle,
    }),
    scaledSize,
    labelOrigin,
  };

  return icon;
}
