import { Tooltip } from 'antd';
import { memo, type ReactNode, type CSSProperties } from 'react';
import styled from 'styled-components';

const Ul = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const Li = styled.li`
  display: flex;
  align-items: center;
  gap: 6px;
`;

const Span = styled.span<{ $canBeClicked: boolean }>`
  cursor: ${({ $canBeClicked }) => ($canBeClicked ? 'pointer' : 'auto')};

  &:hover {
    text-decoration: ${({ $canBeClicked }) => ($canBeClicked ? 'underline' : 'none')};
  }
`;

export interface ListItem {
  title?: string;
  description?: ReactNode;
  descriptionTooltip?: ReactNode;
  onClick?: () => void;
}

interface Props {
  className?: string;
  style?: CSSProperties;
  items: ListItem[];
}

const PopupSectionList = memo(({ items, className, style }: Props) => {
  if (items.length === 0) {
    return null;
  }

  return (
    <Ul className={className} style={style}>
      {items.map((item) => (
        <Li key={item.title}>
          {item.title && (
            <b>
              <Span onClick={item.onClick} $canBeClicked={!!item.onClick}>
                {item.title}
              </Span>
              :
            </b>
          )}
          {item.description && (
            <span style={{ cursor: item.descriptionTooltip ? 'help' : 'auto' }}>
              {item.descriptionTooltip ? (
                <Tooltip title={item.descriptionTooltip} placement="right">
                  {item.description}
                </Tooltip>
              ) : (
                item.description
              )}
            </span>
          )}
        </Li>
      ))}
    </Ul>
  );
});

PopupSectionList.displayName = 'PopupSectionList';

export default PopupSectionList;
