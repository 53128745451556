import { memo, type ReactNode, type CSSProperties } from 'react';
import styled from 'styled-components';

import theme from '~/theme';

const WrapperDiv = styled.div`
  padding: 16px;
  background: ${theme.colors.greyBackground};
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.05);
`;

interface Props {
  children: ReactNode;
  className?: string;
  style?: CSSProperties;
  'data-id'?: string;
}

const PaperCard = memo(({ children, className, style, 'data-id': dataId }: Props) => (
  <WrapperDiv className={className} style={style} data-id={dataId}>
    {children}
  </WrapperDiv>
));

PaperCard.displayName = 'PaperCard';

export default PaperCard;
