import ExclamationCircleTwoTone from '@ant-design/icons/ExclamationCircleTwoTone';
import { Space, Tooltip } from 'antd';
import { memo } from 'react';
import { FiMapPin } from 'react-icons/fi';

import { getTimeAgoAsString } from '~/components/TimeAgo';
import i18n from '~/locales/i18n';
import theme from '~/theme';
import type { Agent } from '~/types/agent';
import type { GpsSensorType } from '~/types/sensor';
import { isAgentLocationOutdated } from '~/utils/agent/isAgentLocationOutdated';
import { GREY_TWO_TONE_COLOR } from '~/utils/equipment/getEquipmentStatusDysfunctionsColor';

import LabelWithValue from './LabelWithValue';

interface Props {
  address: string | undefined;
  gpsSensor: GpsSensorType | null | undefined;
  agent: Agent | undefined;
}

const InfoLocation = memo(({ address, gpsSensor, agent }: Props) => {
  const gpsLocation =
    gpsSensor?.value?.lat && gpsSensor?.value?.lng
      ? `${gpsSensor?.value?.lat}, ${gpsSensor?.value?.lng}`
      : undefined;

  const addressToRender = address || gpsLocation;

  const isLocationOutdated = isAgentLocationOutdated({
    gpsSensor: gpsSensor || null,
    isOffline: false,
  });

  return (
    <Space align="center">
      <FiMapPin color={theme.colors.darkBlue} size={22} />
      <LabelWithValue
        data-id="DetailsPopup-InfoLocation"
        label={i18n.t('common.location')}
        value={addressToRender || i18n.t<string>('carrierDetailsPopup.locationNotFound')}
        hasCopyToClipboard={!!addressToRender}
        hasOpacity={isLocationOutdated}
      />
      {isLocationOutdated && gpsSensor?.timestamp && (
        <Tooltip
          title={
            <div>
              {i18n.t('common.lastLocation')}: {getTimeAgoAsString(gpsSensor?.timestamp)}
            </div>
          }
          placement="right"
        >
          <div style={{ color: theme.colors.yellow, cursor: 'help' }}>
            <ExclamationCircleTwoTone
              size={24}
              twoToneColor={
                agent?.isConnectionLost || agent?.isOffline
                  ? GREY_TWO_TONE_COLOR
                  : theme.colors.yellow
              }
            />
          </div>
        </Tooltip>
      )}
    </Space>
  );
});

InfoLocation.displayName = 'InfoLocation';

export default InfoLocation;
