import { gql, useQuery, type ApolloError } from '@apollo/client';
import { useMemo } from 'react';

import { MEASUREMENT_ITEMS_QUERY_BODY } from '~/apollo/operations/shared';
import {
  SENSOR_NAME_QUERY,
  SENSOR_NAME_VARIABLE,
  type PhysiologicalTemperatureSensorType,
} from '~/types/sensor';

import type { MeasurementDataPoint } from './types';

interface BodyMultiSensorV1MeasurementHistoryQueryVariables {
  carrierId: string;
}

interface BodyMultiSensorV1MeasurementHistoryQueryData {
  carrier: {
    device: {
      __typename?: 'Device';
      bodyMultiSensorV1: {
        __typename?: 'MeasurementConnection';
        items: PhysiologicalTemperatureSensorType[];
      };
    };
  };
}

export default function useQueryBodyMultiSensorV1MeasurementHistory({
  carrierId,
  skip,
}: {
  carrierId: string;
  skip: boolean;
}): {
  bodyTemperatureDataPoints: MeasurementDataPoint[];
  skinTemperatureDataPoints: MeasurementDataPoint[];
  dataQualityDataPoints: MeasurementDataPoint[];
  isLoading: boolean;
  error?: ApolloError;
  refetch: () => Promise<void>;
} {
  const { loading, error, data, refetch } = useQuery<
    BodyMultiSensorV1MeasurementHistoryQueryData,
    BodyMultiSensorV1MeasurementHistoryQueryVariables
  >(
    gql`
    query QueryBodyMultiSensorV1MeasurementHistory($carrierId: ID!) {
        carrier(id: $carrierId) {
            device {
              ${SENSOR_NAME_VARIABLE.bodyMultiSensorV1}: measurements(type: "${SENSOR_NAME_QUERY.body_multi_sensor_v1}", limit: 1000) ${MEASUREMENT_ITEMS_QUERY_BODY}
            }
          }
      }
    
  `,
    {
      variables: { carrierId },
      fetchPolicy: 'no-cache',
      skip,
    },
  );

  const parsedItems = useMemo(
    () =>
      (data?.carrier?.device?.bodyMultiSensorV1?.items || []).map((items) => ({
        ...items,
        value: JSON.parse(
          items.value as unknown as string,
        ) as PhysiologicalTemperatureSensorType['value'],
      })),
    [data?.carrier?.device?.bodyMultiSensorV1?.items],
  );

  return useMemo(
    () => ({
      bodyTemperatureDataPoints: parsedItems
        .map((item) => ({
          value: parseFloat(`${item?.value?.body_temp}`),
          timestamp: item.timestamp,
        }))
        .filter((dataPoint) => Number.isFinite(dataPoint.value)),
      skinTemperatureDataPoints: parsedItems
        .map((item) => ({
          value: parseFloat(`${item?.value?.skin_temp}`),
          timestamp: item.timestamp,
        }))
        .filter((dataPoint) => Number.isFinite(dataPoint.value)),
      dataQualityDataPoints: parsedItems
        .map((item) => ({
          value: parseFloat(`${item?.value?.data_qual}`),
          timestamp: item.timestamp,
        }))
        .filter((dataPoint) => Number.isFinite(dataPoint.value)),
      isLoading: loading,
      error,
      refetch: async () => {
        await refetch();
      },
    }),
    [parsedItems, loading, error, refetch],
  );
}
