import { notification as antdNotification } from 'antd';

import TimeAgo from '~/components/TimeAgo';

type NotificationApi = typeof antdNotification;

type Options = Parameters<NotificationApi['open']>[0];

const defaultOptions: Partial<Options> = {
  placement: 'topRight',
  duration: 3,
};

function curateOptions(options: Options): Options {
  return {
    ...defaultOptions,
    ...options,
    description: (
      <div>
        <div>{options.description}</div>
        <div style={{ fontSize: '12px', color: '#888', marginTop: '0.5rem' }}>
          <TimeAgo date={new Date()} />
        </div>
      </div>
    ),
  };
}

const notification: Pick<NotificationApi, 'success' | 'info' | 'warning' | 'error' | 'destroy'> = {
  ...antdNotification,
  success: (options: Options) => {
    antdNotification.success(curateOptions(options));
  },
  info: (options: Options) => {
    antdNotification.info(curateOptions(options));
  },
  warning: (options: Options) => {
    antdNotification.warning(curateOptions(options));
  },
  error: (options: Options) => {
    antdNotification.error(curateOptions(options));
  },
};

export default notification;
