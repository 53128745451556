import { datadogRum } from '@datadog/browser-rum';
import { Wrapper as GoogleMapsWrapper } from '@googlemaps/react-wrapper';
import { ConfigProvider as AntdConfigProvider } from 'antd';
import { useEffect, useState } from 'react';
import { I18nextProvider } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';

import {
  currentCompanyIdentifierVar,
  CURRENT_COMPANY_IDENTIFIER_DEFAULT_VALUE,
} from '~/apollo/reactiveVariables/currentCompanyIdentifierVar';
import {
  currentSubsidiaryIdentifierVar,
  CURRENT_SUBSIDIARY_IDENTIFIER_DEFAULT_VALUE,
} from '~/apollo/reactiveVariables/currentSubsidiaryIdentifierVar';
import OfflineOverlay from '~/components/OfflineOverlay';
import variables from '~/config/variables';
import { AppSyncApolloProvider } from '~/context/AppSyncApolloContext';
import useAuthenticationContext, {
  AuthenticationContextProvider,
} from '~/context/useAuthenticationContext';
import useCurrentUserContext from '~/context/useCurrentUserContext';
import AppLayout from '~/layouts/AppLayout';
import i18n, { getAntdLocale } from '~/locales/i18n';
import AppRoutes from '~/router/AppRoutes';
import useUserInteractions from '~/store/useUserInteractions';
import GlobalStyles from '~/styles/global.css';
import theme from '~/theme';
import getUserName from '~/utils/user/getUserName';

function OfflineState() {
  const { isAuthenticated } = useAuthenticationContext();
  const [isOffline, setIsOffline] = useState<boolean>(false);

  useEffect(() => {
    const handleIsNowOnline = () => {
      setIsOffline(false);
      if (isAuthenticated) {
        window.location.reload();
      }
    };

    const handleIsNowOffline = () => {
      setIsOffline(true);
    };

    window.addEventListener('online', handleIsNowOnline);
    window.addEventListener('offline', handleIsNowOffline);

    return () => {
      window.removeEventListener('online', handleIsNowOnline);
      window.removeEventListener('offline', handleIsNowOffline);
    };
  }, [isAuthenticated]);

  return isOffline ? <OfflineOverlay /> : null;
}

function AppLogic() {
  const { isAuthenticated } = useAuthenticationContext();

  const setUserInteractedWithDocument = useUserInteractions(
    (state) => state.setUserInteractedWithDocument,
  );

  useEffect(() => {
    const handleUserInteraction = () => setUserInteractedWithDocument(true);

    document.addEventListener('keydown', handleUserInteraction);
    document.addEventListener('click', handleUserInteraction);
    document.addEventListener('touchstart', handleUserInteraction);
    document.addEventListener('scroll', handleUserInteraction);
  }, [setUserInteractedWithDocument]);

  useEffect(() => {
    if (!isAuthenticated) {
      currentSubsidiaryIdentifierVar(CURRENT_SUBSIDIARY_IDENTIFIER_DEFAULT_VALUE);
      currentCompanyIdentifierVar(CURRENT_COMPANY_IDENTIFIER_DEFAULT_VALUE);
    }
  }, [isAuthenticated]);

  return null;
}

function DatadogConfig() {
  const { isAuthenticated } = useAuthenticationContext();
  const { currentUser } = useCurrentUserContext();

  useEffect(() => {
    if (isAuthenticated && currentUser?.email) {
      datadogRum.setUser({
        email: currentUser.email,
        id: currentUser.id,
        name: getUserName(currentUser),
      });
    } else {
      datadogRum.clearUser();
    }
  }, [isAuthenticated, currentUser]);

  return null;
}

export default function App() {
  return (
    <GoogleMapsWrapper apiKey={variables.googleMapsApiKey} libraries={['places', 'marker']}>
      <I18nextProvider i18n={i18n}>
        <AntdConfigProvider
          locale={getAntdLocale()}
          theme={{
            token: {
              colorPrimary: theme.colors.primaryBlue,
            },
          }}
        >
          <AuthenticationContextProvider>
            <BrowserRouter basename={variables.basename}>
              <AppSyncApolloProvider>
                <GlobalStyles />
                <AppLayout>
                  <AppRoutes />
                </AppLayout>
                <OfflineState />
                <AppLogic />
                <DatadogConfig />
              </AppSyncApolloProvider>
            </BrowserRouter>
          </AuthenticationContextProvider>
        </AntdConfigProvider>
      </I18nextProvider>
    </GoogleMapsWrapper>
  );
}
