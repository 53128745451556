import type { ApolloError } from '@apollo/client';
import orderBy from 'lodash/orderBy';
import { useCallback, useMemo } from 'react';

import type { VideoRecording } from '~/types/videoRecording';

import useQueryCarrierVideoRecordingsV1 from './useQueryCarrierVideoRecordingsV1';
import useQueryCarrierVideoRecordingsV2 from './useQueryCarrierVideoRecordingsV2';

export default function useQueryCarrierVideoRecordings({
  agentId,
  startAfter,
  startBefore,
}: {
  agentId: string;
  startAfter: string | null;
  startBefore: string | null;
}): {
  videoRecordings: VideoRecording[];
  isLoading: boolean;
  error: ApolloError | undefined;
  refetchVideoRecordings: () => void;
  videoRecordingsV1NextToken: string | null;
  videoRecordingsV2NextToken: string | null;
  fetchNextPageV1: (nextToken: string | null) => void;
  fetchNextPageV2: (nextToken: string | null) => void;
} {
  const {
    videoRecordingsV1,
    refetchVideoRecordingsV1,
    fetchNextPageV1,
    isLoading: isVideoRecordingsV1Loading,
    videoRecordingsV1NextToken,
    error: videoRecordingsV1Error,
  } = useQueryCarrierVideoRecordingsV1({
    agentId,
    startAfter,
    startBefore,
  });

  const {
    videoRecordingsV2,
    refetchVideoRecordingsV2,
    fetchNextPageV2,
    isLoading: isVideoRecordingsV2Loading,
    videoRecordingsV2NextToken,
    error: videoRecordingsV2Error,
  } = useQueryCarrierVideoRecordingsV2({
    agentId,
    startAfter,
    startBefore,
  });

  const refetchVideoRecordings = useCallback(() => {
    refetchVideoRecordingsV1();
    refetchVideoRecordingsV2();
  }, [refetchVideoRecordingsV1, refetchVideoRecordingsV2]);

  const isLoading = isVideoRecordingsV1Loading || isVideoRecordingsV2Loading;

  const videoRecordings = useMemo(
    () =>
      isLoading
        ? []
        : orderBy(
            [...videoRecordingsV1, ...videoRecordingsV2].filter((video) => !!video.duration_ms),
            ['start_recording'],
            ['desc'],
          ),
    [isLoading, videoRecordingsV1, videoRecordingsV2],
  );

  return useMemo(
    () => ({
      videoRecordings,
      isLoading,
      error: videoRecordingsV1Error || videoRecordingsV2Error,
      refetchVideoRecordings,
      videoRecordingsV1NextToken,
      videoRecordingsV2NextToken,
      fetchNextPageV1,
      fetchNextPageV2,
    }),
    [
      fetchNextPageV1,
      fetchNextPageV2,
      isLoading,
      refetchVideoRecordings,
      videoRecordings,
      videoRecordingsV1Error,
      videoRecordingsV1NextToken,
      videoRecordingsV2Error,
      videoRecordingsV2NextToken,
    ],
  );
}
