import ExclamationCircleTwoTone from '@ant-design/icons/ExclamationCircleTwoTone';
import intersection from 'lodash/intersection';
import uniq from 'lodash/uniq';
import { useCallback, useMemo } from 'react';
import { renderToString } from 'react-dom/server';
import { useNavigate } from 'react-router-dom';

import { getTimeAgoAsString } from '~/components/TimeAgo';
import { DEFAULT_GPS_POSITION } from '~/config/defaults';
import routes from '~/config/routes';
import useAgentsContext from '~/context/useAgentsContext';
import useAlarmsContext from '~/context/useAlarmsContext';
import useAgentStatuses from '~/hooks/useAgentStatuses';
import useCompanyFeatures, { type CompanyFeatures } from '~/hooks/useCompanyFeatures';
import useQueryParams from '~/hooks/useQueryParams';
import useTeams from '~/hooks/useTeams';
import i18n from '~/locales/i18n';
import theme from '~/theme';
import { AGENT_STATUS, type AgentAlertLevel } from '~/types/agent';
import { MARKER_TYPE } from '~/types/marker';
import type { GpsCoordinates, GpsSensorType } from '~/types/sensor';
import type { Vehicle } from '~/types/vehicle';
import { isAgentLocationOutdated } from '~/utils/agent/isAgentLocationOutdated';
import getHighestAlarmLevel from '~/utils/alarm/getHighestAlarmLevel';
import { GREY_TWO_TONE_COLOR } from '~/utils/equipment/getEquipmentStatusDysfunctionsColor';
import computeVehicleShorthandLabel from '~/utils/vehicle/computeVehicleShorthandLabel';

import getMarkerZIndex from '../utils/getMarkerZIndex';

function getMarkerTooltip({
  title,
  type,
  parentisis,
  isLocationOutdated,
  gpsTimestamp,
  isConnectionLost,
  isOffline,
}: {
  title: string;
  type: 'agent' | 'vehicle';
  parentisis: string | undefined;
  isLocationOutdated: boolean;
  gpsTimestamp: string | undefined;
  isConnectionLost: boolean;
  isOffline: boolean;
}) {
  return `<div style="text-align:center" data-id="agent-marker-tooltip">
      ${
        isLocationOutdated && gpsTimestamp
          ? `${renderToString(
              <ExclamationCircleTwoTone
                size={24}
                twoToneColor={
                  isConnectionLost || isOffline ? GREY_TWO_TONE_COLOR : theme.colors.yellow
                }
              />,
            )} ${i18n.t('common.lastLocation')}: ${getTimeAgoAsString(gpsTimestamp)}<br /><br />`
          : ''
      }
            <b>${title}</b>
            <br />
            ${i18n.t(`common.${type}`)}${parentisis ? ` (${parentisis})` : ''}
          </div>`;
}

export interface MarkerType extends google.maps.MarkerOptions {
  id: string;
  type: MARKER_TYPE;
  label: string;
  tooltip: string;
  status: AGENT_STATUS;
  alertLevel: AgentAlertLevel;
  position: GpsCoordinates;
  accuracyCircleRadius: number;
  isHighlighted?: boolean;
  onClick?: (markerId: string) => void;
}

function getAccuracyCircleRadiusInMeters({
  gps,
  showLocationAccuracyCircle,
}: {
  gps: GpsSensorType | null;
  showLocationAccuracyCircle: CompanyFeatures['showLocationAccuracyCircle'];
}): number {
  if (!showLocationAccuracyCircle || !gps?.value?.acc) {
    return 0;
  }
  const radiusInMeters = (gps.value.acc / 1000) * 3;
  if (radiusInMeters < 2.5) {
    return 0;
  }
  if (radiusInMeters > 500) {
    return 500;
  }
  return radiusInMeters;
}

function getVehicleTeams(vehicle: Vehicle) {
  return uniq(vehicle.agents?.map(({ team }) => team).filter((val) => val));
}

export default function useMarkers() {
  const navigate = useNavigate();
  const { id: queryAgentId } = useQueryParams();
  const { agents, vehicles } = useAgentsContext();
  const { selectedTeams } = useTeams();
  const { selectedAgentStatuses } = useAgentStatuses();
  const { ongoingAlarms } = useAlarmsContext();
  const { companyFeatures } = useCompanyFeatures();

  const handleAgentMarkerClick = useCallback(
    (carrierId: string) => navigate(routes.status({ id: carrierId })),
    [navigate],
  );

  const handleVehicleMarkerClick = useCallback(
    (carrierId: string) => navigate(routes.status({ id: carrierId })),
    [navigate],
  );

  const agentMarkers: MarkerType[] = useMemo(
    () =>
      agents
        .filter(
          (agent) =>
            agent &&
            agent.sensors.gps &&
            (!agent.isOffline || agent.status === AGENT_STATUS.alert) &&
            !agent.attributes.plate_number && // out of vehicle
            (!selectedTeams.length || selectedTeams.includes(agent.team)),
        )
        .map((agent, index) => {
          const isHighlighted = agent.id === queryAgentId;
          const agentAlarms = ongoingAlarms.filter((alarm) => alarm.carrier.id === agent.id);
          const alertLevel = getHighestAlarmLevel(agentAlarms);
          const isLocationOutdated = isAgentLocationOutdated({
            gpsSensor: agent.sensors.gps,
            isOffline: agent.isOffline,
          });
          return {
            id: agent.id,
            position: agent.sensors.gps?.value || DEFAULT_GPS_POSITION,
            type: MARKER_TYPE.carrier,
            label: agent.attributes?.acronym,
            tooltip: getMarkerTooltip({
              title: agent.completeName,
              type: 'agent',
              parentisis: agent.team,
              isLocationOutdated,
              gpsTimestamp: agent?.sensors?.gps?.timestamp,
              isConnectionLost: agent.isConnectionLost,
              isOffline: agent.isOffline,
            }),
            status: agent.status,
            alertLevel,
            accuracyCircleRadius: getAccuracyCircleRadiusInMeters({
              gps: agent.sensors.gps,
              showLocationAccuracyCircle: companyFeatures.showLocationAccuracyCircle,
            }),
            zIndex: getMarkerZIndex(agent.status, isHighlighted, 'agent') + index,
            onClick: handleAgentMarkerClick,
            isHighlighted,
          };
        }),
    [
      agents,
      selectedTeams,
      queryAgentId,
      ongoingAlarms,
      companyFeatures.showLocationAccuracyCircle,
      handleAgentMarkerClick,
    ],
  );

  const vehicleMarkers: MarkerType[] = useMemo(
    () =>
      vehicles
        .filter(
          (vehicle) =>
            vehicle.gps &&
            (!selectedTeams.length || intersection(selectedTeams, getVehicleTeams(vehicle)).length),
        )
        .map((vehicle, index) => {
          const isHighlighted = vehicle.agents.some(({ id }) => id === queryAgentId);
          const alarmsFromAgentsInVehicle = vehicle.agents.flatMap((agent) =>
            ongoingAlarms.filter((alarm) => alarm.carrier.id === agent.id),
          );
          const alertLevel = getHighestAlarmLevel(alarmsFromAgentsInVehicle);
          const isLocationOutdated = isAgentLocationOutdated({
            gpsSensor: vehicle.gps,
            isOffline: false,
          });
          return {
            id: vehicle.id,
            position: vehicle.gps?.value,
            type: MARKER_TYPE.vehicle,
            label: computeVehicleShorthandLabel(vehicle.plateNumber),
            tooltip: getMarkerTooltip({
              title: vehicle.plateNumber,
              type: 'vehicle',
              parentisis: undefined,
              isLocationOutdated,
              gpsTimestamp: vehicle.gps?.timestamp,
              isConnectionLost: vehicle.isConnectionLost,
              isOffline: false,
            }),
            status: vehicle.status,
            alertLevel,
            accuracyCircleRadius: getAccuracyCircleRadiusInMeters({
              gps: vehicle.gps,
              showLocationAccuracyCircle: companyFeatures.showLocationAccuracyCircle,
            }),
            zIndex: getMarkerZIndex(vehicle.status, isHighlighted, 'vehicle') + index,
            onClick: () => {
              handleVehicleMarkerClick(vehicle.agents[0]?.id);
            },
            isHighlighted,
          };
        }),
    [
      vehicles,
      selectedTeams,
      queryAgentId,
      ongoingAlarms,
      companyFeatures.showLocationAccuracyCircle,
      handleVehicleMarkerClick,
    ],
  );

  const markers = useMemo(() => {
    const allMarkers = [...agentMarkers, ...vehicleMarkers];

    return selectedAgentStatuses.length === 0
      ? allMarkers
      : allMarkers.filter((marker) => selectedAgentStatuses.includes(marker.status));
  }, [agentMarkers, vehicleMarkers, selectedAgentStatuses]);

  return useMemo(() => markers, [markers]);
}
