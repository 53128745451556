import type { GpsSensorType } from '~/types/sensor';

const FIVE_MINUTES_IN_MS = 1000 * 60 * 5;

export function isAgentLocationOutdated({
  gpsSensor,
  isOffline,
}: {
  gpsSensor: GpsSensorType | null;
  isOffline: boolean;
}): boolean {
  const hasNoLocation = !gpsSensor?.value?.lat || !gpsSensor?.value?.lng;

  const lastLocationUpdate = gpsSensor?.timestamp;

  if (hasNoLocation || isOffline || !lastLocationUpdate) {
    return true;
  }

  const locationTimestamp = lastLocationUpdate ? new Date(lastLocationUpdate).getTime() : 0;

  const timeDifference = Date.now() - locationTimestamp;

  return timeDifference >= FIVE_MINUTES_IN_MS;
}
