import { gql, useQuery, type ApolloError } from '@apollo/client';
import { useMemo } from 'react';

import { MEASUREMENT_ITEMS_QUERY_BODY } from '~/apollo/operations/shared';
import {
  SENSOR_NAME_QUERY,
  SENSOR_NAME_VARIABLE,
  type ActivityLevelSensorType,
} from '~/types/sensor';

import type { MeasurementDataPoint } from './types';

interface ActivityLevelMeasurementHistoryQueryVariables {
  carrierId: string;
}

interface ActivityLevelMeasurementHistoryQueryData {
  carrier: {
    device: {
      __typename?: 'Device';
      activityLevel: {
        __typename?: 'MeasurementConnection';
        items: ActivityLevelSensorType[];
      };
    };
  };
}

export default function useQueryActivityLevelMeasurementHistory({
  carrierId,
  skip,
}: {
  carrierId: string;
  skip: boolean;
}): {
  activityLevelDataPoints: MeasurementDataPoint[];
  isLoading: boolean;
  error?: ApolloError;
  refetch: () => Promise<void>;
} {
  const { loading, error, data, refetch } = useQuery<
    ActivityLevelMeasurementHistoryQueryData,
    ActivityLevelMeasurementHistoryQueryVariables
  >(
    gql`
    query QueryActivityLevelMeasurementHistory($carrierId: ID!) {
        carrier(id: $carrierId) {
            device {
              ${SENSOR_NAME_VARIABLE.activityLevel}: measurements(type: "${SENSOR_NAME_QUERY.activity_level}", limit: 1000) ${MEASUREMENT_ITEMS_QUERY_BODY}
            }
          }
      }
    
  `,
    {
      variables: { carrierId },
      fetchPolicy: 'no-cache',
      skip,
    },
  );

  return useMemo(
    () => ({
      activityLevelDataPoints: (data?.carrier?.device?.activityLevel?.items || [])
        .map((item) => ({
          value: parseFloat(`${item.value}`),
          timestamp: item.timestamp,
        }))
        .filter((dataPoint) => Number.isFinite(dataPoint.value)),
      isLoading: loading,
      error,
      refetch: async () => {
        await refetch();
      },
    }),
    [data?.carrier?.device?.activityLevel?.items, loading, error, refetch],
  );
}
