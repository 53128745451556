import { gql, useQuery, type ApolloError } from '@apollo/client';
import { useMemo } from 'react';

import { MEASUREMENT_ITEMS_QUERY_BODY } from '~/apollo/operations/shared';
import {
  SENSOR_NAME_QUERY,
  SENSOR_NAME_VARIABLE,
  type LteSignalStrengthSensorType,
} from '~/types/sensor';
import getActualBarsFromLteSignal from '~/utils/lteSignal/getActualBarsFromLteSignal';

import type { MeasurementDataPoint } from './types';

interface LteSignalStrengthMeasurementHistoryQueryVariables {
  carrierId: string;
}

interface LteSignalStrengthMeasurementHistoryQueryData {
  carrier: {
    device: {
      __typename?: 'Device';
      lteSignalStrength: {
        __typename?: 'MeasurementConnection';
        items: LteSignalStrengthSensorType[];
      };
    };
  };
}

export default function useQueryLteSignalStrengthMeasurementHistory({
  carrierId,
  skip,
}: {
  carrierId: string;
  skip: boolean;
}): {
  lteNetworkConnectivityDataPoints: MeasurementDataPoint[];
  lteDataTransmissionDataPoints: MeasurementDataPoint[];
  lteBarsDataPoints: MeasurementDataPoint[];
  isLoading: boolean;
  error?: ApolloError;
  refetch: () => Promise<void>;
} {
  const { loading, error, data, refetch } = useQuery<
    LteSignalStrengthMeasurementHistoryQueryData,
    LteSignalStrengthMeasurementHistoryQueryVariables
  >(
    gql`
    query QueryLteSignalStrengthMeasurementHistory($carrierId: ID!) {
        carrier(id: $carrierId) {
            device {
              ${SENSOR_NAME_VARIABLE.lteSignalStrength}: measurements(type: "${SENSOR_NAME_QUERY.lte_signal_strength}", limit: 1000) ${MEASUREMENT_ITEMS_QUERY_BODY}
            }
          }
      }
    
  `,
    {
      variables: { carrierId },
      fetchPolicy: 'no-cache',
      skip,
    },
  );

  const parsedItems = useMemo(
    () =>
      (data?.carrier?.device?.lteSignalStrength?.items || []).map((items) => ({
        ...items,
        value: JSON.parse(items.value as unknown as string) as LteSignalStrengthSensorType['value'],
      })),
    [data?.carrier?.device?.lteSignalStrength?.items],
  );

  return useMemo(
    () => ({
      lteNetworkConnectivityDataPoints: parsedItems
        .map((item) => ({
          value: parseFloat(`${item.value.rsrp}`),
          timestamp: item.timestamp,
        }))
        .filter((dataPoint) => Number.isFinite(dataPoint.value)),
      lteDataTransmissionDataPoints: parsedItems
        .map((item) => ({
          value: parseFloat(`${item.value.rsrq}`),
          timestamp: item.timestamp,
        }))
        .filter((dataPoint) => Number.isFinite(dataPoint.value)),
      lteBarsDataPoints: parsedItems
        .map((item) => ({
          value: getActualBarsFromLteSignal(parseFloat(`${item.value.bars}`)),
          timestamp: item.timestamp,
        }))
        .filter((dataPoint) => Number.isFinite(dataPoint.value)),
      isLoading: loading,
      error,
      refetch: async () => {
        await refetch();
      },
    }),
    [parsedItems, loading, error, refetch],
  );
}
