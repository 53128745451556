import { useQuery, type ApolloError } from '@apollo/client';
import { gql } from '@apollo/client';
import { useEffect, useMemo, useState } from 'react';
import { useCookies, type ReactCookieProps } from 'react-cookie';

import type { VideoRecordingV1 } from '~/types/videoRecording';

interface VideoRecordingV1QueryVariables {
  videoRecordingId: string;
}

interface VideoRecordingV1QueryData {
  video_recording: VideoRecordingV1;
}

export default function useQueryVideoRecordingV1({
  videoRecordingId,
  skip,
}: {
  videoRecordingId: string | undefined;
  skip: boolean;
}): {
  videoRecordingV1?: VideoRecordingV1QueryData['video_recording'];
  isLoading: boolean;
  error?: ApolloError;
} {
  const [videoRecording, setVideoRecording] = useState<VideoRecordingV1 | undefined>();

  const { data, loading, error } = useQuery<
    VideoRecordingV1QueryData,
    VideoRecordingV1QueryVariables
  >(
    gql`
      query QueryVideoRecordingV1($videoRecordingId: ID!) {
        video_recording(id: $videoRecordingId) {
          id
          start_recording
          duration_ms
          view_url
          view_cookies {
            name
            value
          }
        }
      }
    `,
    {
      variables: {
        videoRecordingId: videoRecordingId || '',
      },
      skip: skip || !videoRecordingId,
    },
  );

  const [, setCookie, removeCookie] = useCookies();

  useEffect(() => {
    if (data?.video_recording?.view_cookies) {
      const url = data.video_recording?.view_url || '';
      data.video_recording.view_cookies.forEach((cookie) => {
        const options: ReactCookieProps['defaultSetOptions'] = {
          path: url.substring(0, url.lastIndexOf('/')), // Remove .m3u8 filename
        };
        removeCookie(cookie.name, options);
        setCookie(cookie.name, cookie.value, options);
      });
      setVideoRecording(data.video_recording);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(data?.video_recording?.view_cookies)]);

  return useMemo(
    () => ({
      videoRecordingV1: videoRecording,
      isLoading: loading,
      error,
    }),
    [videoRecording, loading, error],
  );
}
