import SwitcherOutlined from '@ant-design/icons/SwitcherOutlined';
import { Button, Skeleton } from 'antd';
import { memo, useState } from 'react';
import styled from 'styled-components';

import SettingsSelectCompany from '~/components/settings/components/SettingsSelectCompany';
import TabSectionHeader from '~/components/settings/components/TabSectionHeader';
import { SelectCompanySubsidiaryDiv, SettingsTopFlexDiv } from '~/components/settings/shared';
import useCompany from '~/hooks/useCompany';
import useCompanyMobileAppFeatures from '~/hooks/useCompanyMobileAppFeatures';
import i18n from '~/locales/i18n';

import AppFeaturesForm from './components/AppFeaturesForm/AppFeaturesForm';
import AppFeaturesJSON from './components/AppFeaturesJSON';

const ListDiv = styled.div`
  background: white;
  padding: 16px;
  border-radius: 8px;

  .ant-list-item {
    &:last-of-type {
      padding-bottom: 0;
    }
  }
`;

const TabAppFeatures = memo(() => {
  const { currentCompany } = useCompany();

  const { mobileAppFeatures, isSetMobileAppFeaturesLoading, areMobileAppFeaturesLoaded } =
    useCompanyMobileAppFeatures();

  const [editMode, setEditMode] = useState<'form' | 'json'>('form');

  return (
    <>
      <SelectCompanySubsidiaryDiv>
        <div>
          <TabSectionHeader title={i18n.t('companyFeatures.selectedCompany')} />
          <SettingsSelectCompany />
        </div>
      </SelectCompanySubsidiaryDiv>
      <div>
        <SettingsTopFlexDiv>
          {!currentCompany?.id ? (
            <TabSectionHeader title={i18n.t('companyFeatures.missingCompany')} />
          ) : (
            <>
              <TabSectionHeader title={`Schema version: ${mobileAppFeatures.schemaVersion}`} />
              {editMode === 'json' ? (
                <Button
                  type="default"
                  icon={<SwitcherOutlined />}
                  disabled={isSetMobileAppFeaturesLoading}
                  onClick={() => {
                    setEditMode('form');
                  }}
                >
                  {i18n.t('mobileAppFeatures.switchToForm')}
                </Button>
              ) : (
                <Button
                  type="default"
                  icon={<SwitcherOutlined />}
                  disabled={isSetMobileAppFeaturesLoading}
                  onClick={() => {
                    setEditMode('json');
                  }}
                >
                  {i18n.t('mobileAppFeatures.switchToJSON')}
                </Button>
              )}
            </>
          )}
        </SettingsTopFlexDiv>
        <ListDiv>
          <TabSectionHeader
            title={i18n.t('mobileAppFeatures.mobileAppConfiguration')}
            style={{ marginTop: 0 }}
          />
          {areMobileAppFeaturesLoaded ? (
            <>
              {editMode === 'form' && <AppFeaturesForm />}
              {editMode === 'json' && <AppFeaturesJSON />}
            </>
          ) : (
            <>
              <br />
              <Skeleton active />
              <br />
              {i18n.t('common.loading')}
            </>
          )}
        </ListDiv>
      </div>
    </>
  );
});

TabAppFeatures.displayName = 'TabAppFeatures';

export default TabAppFeatures;
