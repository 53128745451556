import ApiOutlined from '@ant-design/icons/ApiOutlined';
import BellOutlined from '@ant-design/icons/BellOutlined';
import ExclamationCircleOutlined from '@ant-design/icons/ExclamationCircleOutlined';
import LaptopOutlined from '@ant-design/icons/LaptopOutlined';
import MobileOutlined from '@ant-design/icons/MobileOutlined';
import ProductOutlined from '@ant-design/icons/ProductOutlined';
import ShopOutlined from '@ant-design/icons/ShopOutlined';
import { Tag, Tooltip } from 'antd';
import { memo, useEffect, useMemo, useState, type ReactNode } from 'react';
import { GrUserAdmin, GrUserNew, GrUserSettings } from 'react-icons/gr';
import { TbBuildingSkyscraper } from 'react-icons/tb';

import Container from '~/components/Container';
import PageContentWrapper from '~/components/PageContentWrapper';
import PageHeader from '~/components/PageHeader';
import PageSideTabs, { type PageSideTabItem } from '~/components/PageSideTabs';
import {
  SettingsGridDiv,
  SettingsStickyDiv,
  SettingsTabContentDiv,
  SettingsTabTitleH3,
} from '~/components/settings/shared';
import routes, { type BackofficeTab } from '~/config/routes';
import useCurrentUserContext from '~/context/useCurrentUserContext';
import useQueryParams from '~/hooks/useQueryParams';
import i18n from '~/locales/i18n';
import TabAdmins from '~/pages/CompanySettingsPage/components/TabAdmins';
import TabAgents from '~/pages/CompanySettingsPage/components/TabAgents';
import TabBrainConfigurations from '~/pages/CompanySettingsPage/components/TabBrainConfigurations';
import TabSubsidiaries from '~/pages/CompanySettingsPage/components/TabSubsidiaries';

import TabAlarmConfigurations from './components/TabAlarmConfigurations';
import TabAppFeatures from './components/TabAppFeatures';
import TabBrains from './components/TabBrains';
import TabCompanies from './components/TabCompanies';
import TabDashboardFeatures from './components/TabDashboardFeatures';
import TabSuperAdmins from './components/TabSuperAdmins';

const backofficeTabs: {
  key: BackofficeTab;
  icon: ReactNode;
  isBetaVersion: boolean;
}[] = [
  { key: 'companies', icon: <ShopOutlined />, isBetaVersion: false },
  { key: 'subsidiaries', icon: <TbBuildingSkyscraper />, isBetaVersion: false },
  { key: 'admins', icon: <GrUserSettings />, isBetaVersion: false },
  { key: 'agents', icon: <GrUserNew />, isBetaVersion: false },
  { key: 'brains', icon: <ApiOutlined />, isBetaVersion: true },
  { key: 'brain-configurations', icon: <ProductOutlined />, isBetaVersion: true },
  { key: 'alarm-configurations', icon: <BellOutlined />, isBetaVersion: true },
  { key: 'dashboard-features', icon: <LaptopOutlined />, isBetaVersion: false },
  { key: 'app-features', icon: <MobileOutlined />, isBetaVersion: false },
  { key: 'super-admins', icon: <GrUserAdmin />, isBetaVersion: false },
];

const BackofficePage = memo(() => {
  const { isSuperAdmin } = useCurrentUserContext();
  const queryParams = useQueryParams();

  const [tab, setTab] = useState<BackofficeTab>((queryParams.tab as BackofficeTab) || 'companies');

  useEffect(() => {
    if (queryParams.tab) {
      setTab(queryParams.tab as BackofficeTab);
    }
  }, [queryParams.tab]);

  const activeTab = useMemo(() => backofficeTabs.find(({ key }) => key === tab), [tab]);

  const pageSideTabItems: PageSideTabItem[] = useMemo(
    () =>
      backofficeTabs.map(({ key, icon }) => ({
        key,
        dataId: `backoffice-${key}-link`,
        icon,
        label: i18n.t(`backofficePage.tabs.${key}`),
        href: routes.backoffice({ ...queryParams, tab: key }),
      })),
    [queryParams],
  );

  if (!isSuperAdmin) {
    return null;
  }

  return (
    <PageContentWrapper>
      <Container>
        <SettingsGridDiv>
          <aside>
            <PageHeader
              title={i18n.t('backofficePage.title')}
              subtitle={i18n.t('backofficePage.subtitle')}
            />
            <SettingsStickyDiv>
              <PageSideTabs activeItemKey={tab} items={pageSideTabItems} />
            </SettingsStickyDiv>
          </aside>
          <SettingsTabContentDiv>
            <SettingsTabTitleH3 data-id="backoffice-page-tab-title">
              {i18n.t(`backofficePage.tabs.${tab}`)}
              {activeTab?.isBetaVersion && (
                <span>
                  <Tooltip title={i18n.t('betaVersion.description')} placement="right">
                    <Tag
                      icon={<ExclamationCircleOutlined />}
                      color="blue"
                      style={{ cursor: 'help' }}
                    >
                      {i18n.t('betaVersion.title')}
                    </Tag>
                  </Tooltip>
                </span>
              )}
            </SettingsTabTitleH3>
            {tab === 'companies' && <TabCompanies />}
            {tab === 'subsidiaries' && <TabSubsidiaries isBackofficePage />}
            {tab === 'admins' && <TabAdmins isBackofficePage />}
            {tab === 'brains' && <TabBrains />}
            {tab === 'agents' && <TabAgents isBackofficePage />}
            {tab === 'brain-configurations' && <TabBrainConfigurations isBackofficePage />}
            {tab === 'alarm-configurations' && <TabAlarmConfigurations />}
            {tab === 'dashboard-features' && <TabDashboardFeatures />}
            {tab === 'app-features' && <TabAppFeatures />}
            {tab === 'super-admins' && <TabSuperAdmins />}
          </SettingsTabContentDiv>
        </SettingsGridDiv>
      </Container>
    </PageContentWrapper>
  );
});

BackofficePage.displayName = 'BackofficePage';

export default BackofficePage;
