import { gql, useQuery, type ApolloError } from '@apollo/client';
import { useMemo } from 'react';

import { MEASUREMENT_ITEMS_QUERY_BODY } from '~/apollo/operations/shared';
import {
  SENSOR_NAME_QUERY,
  SENSOR_NAME_VARIABLE,
  type OxygenSupplySensorType,
} from '~/types/sensor';

import type { MeasurementDataPoint } from './types';

interface OxygenSupplyMeasurementHistoryQueryVariables {
  carrierId: string;
}

interface OxygenSupplyMeasurementHistoryQueryData {
  carrier: {
    device: {
      __typename?: 'Device';
      oxygenSupply: {
        __typename?: 'MeasurementConnection';
        items: OxygenSupplySensorType[];
      };
    };
  };
}

export default function useQueryOxygenSupplyMeasurementHistory({
  carrierId,
  skip,
}: {
  carrierId: string;
  skip: boolean;
}): {
  pressureDataPoints: MeasurementDataPoint[];
  remainingTimeDataPoints: MeasurementDataPoint[];
  temperatureDataPoints: MeasurementDataPoint[];
  isLoading: boolean;
  error?: ApolloError;
  refetch: () => Promise<void>;
} {
  const { loading, error, data, refetch } = useQuery<
    OxygenSupplyMeasurementHistoryQueryData,
    OxygenSupplyMeasurementHistoryQueryVariables
  >(
    gql`
    query QueryOxygenSupplyMeasurementHistory($carrierId: ID!) {
        carrier(id: $carrierId) {
            device {
              ${SENSOR_NAME_VARIABLE.oxygenSupply}: measurements(type: "${SENSOR_NAME_QUERY.oxygen_supply}", limit: 1000) ${MEASUREMENT_ITEMS_QUERY_BODY}
            }
          }
      }
    
  `,
    {
      variables: { carrierId },
      fetchPolicy: 'no-cache',
      skip,
    },
  );

  const parsedItems = useMemo(
    () =>
      (data?.carrier?.device?.oxygenSupply?.items || []).map((items) => ({
        ...items,
        value: JSON.parse(items.value as unknown as string) as OxygenSupplySensorType['value'],
      })),
    [data?.carrier?.device?.oxygenSupply?.items],
  );

  return useMemo(
    () => ({
      pressureDataPoints: parsedItems
        .map((item) => ({
          value: parseFloat(`${item.value.pressure}`),
          timestamp: item.timestamp,
        }))
        .filter((dataPoint) => Number.isFinite(dataPoint.value)),
      remainingTimeDataPoints: parsedItems
        .map((item) => ({
          value: parseFloat(`${item.value.remaining_time}`),
          timestamp: item.timestamp,
        }))
        .filter((dataPoint) => Number.isFinite(dataPoint.value)),
      temperatureDataPoints: parsedItems
        .map((item) => ({
          value: parseFloat(`${item.value.temp}`),
          timestamp: item.timestamp,
        }))
        .filter((dataPoint) => Number.isFinite(dataPoint.value)),
      isLoading: loading,
      error,
      refetch: async () => {
        await refetch();
      },
    }),
    [parsedItems, loading, error, refetch],
  );
}
