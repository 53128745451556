import { memo, useMemo } from 'react';

import SectionList from '~/components/DetailsPopup/components/SectionList';
import {
  GAS_CH4_HC_UNIT,
  GAS_CO2_UNIT,
  GAS_CO_UNIT,
  GAS_H2S_UNIT,
  GAS_O2_UNIT,
} from '~/config/units';
import useAlarmsContext from '~/context/useAlarmsContext';
import useModalsContext from '~/context/useModalsContext';
import i18n from '~/locales/i18n';
import theme from '~/theme';
import type { Agent } from '~/types/agent';
import {
  ALARM_TYPE,
  type AlarmWithCarrier,
  type ExistingGas,
  type GasAlarmValue,
} from '~/types/alarm';
import { GAS_SENSOR_TYPE } from '~/types/sensor';
import getEquipmentStatusDysfunctionsColor from '~/utils/equipment/getEquipmentStatusDysfunctionsColor';
import formatNumberWithUnit from '~/utils/parse/formatNumberWithUnit';
import parseJSON from '~/utils/parse/parseJSON';

function hasGasAlarmFromType(ongoingAlarms: AlarmWithCarrier[], type: ExistingGas): boolean {
  const gasDangerAlarms = ongoingAlarms.filter(
    (alarm) => alarm.type === ALARM_TYPE.gas_danger || alarm.type === ALARM_TYPE.gas_high,
  );
  const gasHighAlarms = ongoingAlarms.filter((alarm) => alarm.type === ALARM_TYPE.gas_high);
  const gasAlarms = [...gasDangerAlarms, ...gasHighAlarms];
  return gasAlarms.some((alarm) => {
    const gasName = (parseJSON(alarm.value) as GasAlarmValue)?.gas;
    return gasName === type;
  });
}

interface Props {
  agent: Agent | undefined;
}

const ContentGasSensors = memo(({ agent }: Props) => {
  const { ongoingAlarms } = useAlarmsContext();
  const { openModal } = useModalsContext();

  const agentOngoingAlarms = useMemo(
    () => ongoingAlarms.filter((alarm) => alarm.carrier.id === agent?.id),
    [agent, ongoingAlarms],
  );

  const { isWorking } = getEquipmentStatusDysfunctionsColor(agent?.equipmentStatus?.gas?.status);

  const list: {
    type: GAS_SENSOR_TYPE;
    unit: string;
    hasAlarm: boolean;
    'data-id': string;
  }[] = useMemo(
    () => [
      {
        type: GAS_SENSOR_TYPE.co,
        unit: GAS_CO_UNIT,
        hasAlarm: hasGasAlarmFromType(agentOngoingAlarms, 'co'),
        'data-id': 'gasSensorCO',
        onClickLabel: () => {
          openModal({
            type: 'measurementGraph',
            measurementKind: 'gasCo',
            title: `${i18n.t('carrierDetailsPopup.equipment.gas')} (${i18n.t('carrierDetailsPopup.gasSensors.co')})`,
            agentName: agent?.completeName || '',
            brainId: agent?.deviceName || '',
            carrierId: agent?.id || '',
          });
        },
      },
      {
        type: GAS_SENSOR_TYPE.co2,
        unit: GAS_CO2_UNIT,
        hasAlarm: hasGasAlarmFromType(agentOngoingAlarms, 'co2'),
        'data-id': 'gasSensorCO2',
        onClickLabel: () => {
          openModal({
            type: 'measurementGraph',
            measurementKind: 'gasCo2',
            title: `${i18n.t('carrierDetailsPopup.equipment.gas')} (${i18n.t('carrierDetailsPopup.gasSensors.co2')})`,
            agentName: agent?.completeName || '',
            brainId: agent?.deviceName || '',
            carrierId: agent?.id || '',
          });
        },
      },
      {
        type: GAS_SENSOR_TYPE.h2s,
        unit: GAS_H2S_UNIT,
        hasAlarm: hasGasAlarmFromType(agentOngoingAlarms, 'h2s'),
        'data-id': 'gasSensorH2S',
        onClickLabel: () => {
          openModal({
            type: 'measurementGraph',
            measurementKind: 'gasH2s',
            title: `${i18n.t('carrierDetailsPopup.equipment.gas')} (${i18n.t('carrierDetailsPopup.gasSensors.h2s')})`,
            agentName: agent?.completeName || '',
            brainId: agent?.deviceName || '',
            carrierId: agent?.id || '',
          });
        },
      },
      {
        type: GAS_SENSOR_TYPE.ch4_hc,
        unit: GAS_CH4_HC_UNIT,
        hasAlarm: hasGasAlarmFromType(agentOngoingAlarms, 'ch4/hc'),
        'data-id': 'gasSensorCH4HC',
        onClickLabel: () => {
          openModal({
            type: 'measurementGraph',
            measurementKind: 'gasCh4Hc',
            title: `${i18n.t('carrierDetailsPopup.equipment.gas')} (${i18n.t('carrierDetailsPopup.gasSensors.ch4/hc')})`,
            agentName: agent?.completeName || '',
            brainId: agent?.deviceName || '',
            carrierId: agent?.id || '',
          });
        },
      },
      {
        type: GAS_SENSOR_TYPE.o2,
        unit: GAS_O2_UNIT,
        hasAlarm: hasGasAlarmFromType(agentOngoingAlarms, 'o2'),
        'data-id': 'gasSensorO2',
        onClickLabel: () => {
          openModal({
            type: 'measurementGraph',
            measurementKind: 'gasO2',
            title: `${i18n.t('carrierDetailsPopup.equipment.gas')} (${i18n.t('carrierDetailsPopup.gasSensors.o2')})`,
            agentName: agent?.completeName || '',
            brainId: agent?.deviceName || '',
            carrierId: agent?.id || '',
          });
        },
      },
    ],
    [agent?.completeName, agent?.deviceName, agent?.id, agentOngoingAlarms, openModal],
  );

  const elements = useMemo(
    () =>
      list.map((item) => {
        const gasValue = formatNumberWithUnit({
          number: isWorking ? agent?.sensors?.gas?.value?.[item.type] : undefined,
          unit: item.unit,
        });
        const hasValue = gasValue !== 'n/a';

        return {
          ...item,
          style: {
            color: hasValue && item.hasAlarm ? theme.colors.red : undefined,
            opacity: hasValue ? 1 : 0.4,
          },
          labelText: i18n.t(`carrierDetailsPopup.gasSensors.${item.type}`),
          valueText: gasValue,
          'data-id': item['data-id'],
        };
      }),
    [agent?.sensors?.gas?.value, list, isWorking],
  );

  if (!agent) {
    return null;
  }

  return <SectionList data-id="SectionGasSensors" elements={elements} />;
});

ContentGasSensors.displayName = 'ContentGasSensors';

export default ContentGasSensors;
